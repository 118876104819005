import { useEffect } from 'react'
import TagManager from 'react-gtm-module'

import { GTMParams } from './types/gtm'

const tagManagerId = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID || ''
const tagManagerAuth = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_AUTH || ''
const tagManagerPreview = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_PREVIEW || ''

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: Record<string, any>[]
  }
}

/**
 * 初期化待機用のPromise。
 */
const dummyDataLayer: GTMParams[] = []

/**
 * 初期化処理
 */
let isReady = false
export const useInitGTM = () => {
  useEffect(() => {
    if (isReady) {
      return
    }

    isReady = true
    ;(async () => {
      TagManager.initialize({
        gtmId: tagManagerId,
        auth: tagManagerAuth,
        preview: tagManagerPreview,
      })
      dummyDataLayer.forEach((event) => {
        window.dataLayer.push(event)
      })
    })()
  }, [])
}

/**
 * データレイヤーに値を追加
 */
export const sendGTM = (params: GTMParams) => {
  if (!window.dataLayer) {
    dummyDataLayer.push(params)
  } else {
    window.dataLayer.push(params)
  }
}

type DataLayer = {
  event?: string
  environment?: string
  [key: string]: any
}

/**
 * データレイヤーに値を追加
 * sendGTMと基本的に同じことをやっている
 * GTMParamsの型にとらわれることなく、任意のデータを送信できる
 */
export const pushToDataLayer = (data: DataLayer) => {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(data)
  }
}
