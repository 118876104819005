/* tslint:disable */
/* eslint-disable */
/**
 * z-career API for talent
 * z-career API for talent web applications.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface AddScoutProfileRequest
 */
export interface AddScoutProfileRequest {
  /**
   * 経験社数(RHFの都合で整数値0が扱いにくいため、文字列で受けてバックエンドのRequestクラスで変換する)
   * @type {string}
   * @memberof AddScoutProfileRequest
   */
  past_employment_company_count: string
  /**
   * 現(前)年収
   * @type {number}
   * @memberof AddScoutProfileRequest
   */
  current_annual_salary?: number
  /**
   * 不得意なことマスタID
   * @type {number}
   * @memberof AddScoutProfileRequest
   */
  weakness_master_id: number
  /**
   * 避けたいことマスタID
   * @type {number}
   * @memberof AddScoutProfileRequest
   */
  avoidance_master_id: number
  /**
   * 転職で実現したいこと(希望順)
   * @type {Array<number>}
   * @memberof AddScoutProfileRequest
   */
  talent_ambitions: Array<number>
  /**
   * 希望する仕事環境
   * @type {Array<number>}
   * @memberof AddScoutProfileRequest
   */
  talent_desired_work_environments: Array<number>
  /**
   * 性格に合う働き方
   * @type {Array<number>}
   * @memberof AddScoutProfileRequest
   */
  talent_desired_work_styles: Array<number>
  /**
   * 希望職種
   * @type {Array<number>}
   * @memberof AddScoutProfileRequest
   */
  talent_desired_job_categories: Array<number>
  /**
   * 職歴
   * @type {Array<TalentWorkRecordRequest>}
   * @memberof AddScoutProfileRequest
   */
  talent_work_records: Array<TalentWorkRecordRequest>
}
/**
 *
 * @export
 * @interface Address
 */
export interface Address {
  /**
   * AgentBankDBでのaddress.id
   * @type {number}
   * @memberof Address
   */
  ab_address_id: number
  /**
   *
   * @type {MediumMasterListItem}
   * @memberof Address
   */
  prefecture: MediumMasterListItem
  /**
   * 勤務地_住所
   * @type {string}
   * @memberof Address
   */
  address: string | null
  /**
   * 勤務地_勤務地詳細
   * @type {string}
   * @memberof Address
   */
  detail: string | null
  /**
   * 勤務地_最寄駅
   * @type {Array<Station>}
   * @memberof Address
   */
  stations: Array<Station> | null
  /**
   * 検索条件に使用されたかどうか
   * @type {boolean}
   * @memberof Address
   */
  is_searched?: boolean | null
}
/**
 * 都道府県ごとの勤務地_住所のリスト。検索に指定された都道府県の勤務地は先頭・都道府県ID昇順・都道府県ID重複なし。
 * @export
 * @interface AddressesByPrefecture
 */
export interface AddressesByPrefecture {
  /**
   * 都道府県ID
   * @type {number}
   * @memberof AddressesByPrefecture
   */
  prefecture_master_id: number
  /**
   *
   * @type {Array<Address>}
   * @memberof AddressesByPrefecture
   */
  addresses: Array<Address>
}
/**
 * お気に入り求人一括登録API
 * @export
 * @interface BulkKeepJobRequest
 */
export interface BulkKeepJobRequest {
  /**
   * 求人ID群
   * @type {Array<BulkKeepJobRequestJobIdsInner>}
   * @memberof BulkKeepJobRequest
   */
  job_ids: Array<BulkKeepJobRequestJobIdsInner>
}
/**
 *
 * @export
 * @interface BulkKeepJobRequestJobIdsInner
 */
export interface BulkKeepJobRequestJobIdsInner {
  /**
   *
   * @type {string}
   * @memberof BulkKeepJobRequestJobIdsInner
   */
  job_id: string
  /**
   * unix time
   * @type {string}
   * @memberof BulkKeepJobRequestJobIdsInner
   */
  kept_at: string | null
}
/**
 * 日付単体
 * @export
 * @interface CalendarInner
 */
export interface CalendarInner {
  /**
   * 日付
   * @type {string}
   * @memberof CalendarInner
   */
  date?: string
  /**
   * 曜日（ISO-8601 numeric representation. for example,  1 for Monday, 7 for Sunday.)
   * @type {number}
   * @memberof CalendarInner
   */
  day_of_week?: number
  /**
   * 枠が利用可能かどうか（企業からの連絡が可能）
   * @type {boolean}
   * @memberof CalendarInner
   */
  is_available?: boolean
  /**
   * 日付に対する時間の全体集合
   * @type {Array<CalendarInnerTimesInner>}
   * @memberof CalendarInner
   */
  times?: Array<CalendarInnerTimesInner>
}
/**
 * 時間単体
 * @export
 * @interface CalendarInnerTimesInner
 */
export interface CalendarInnerTimesInner {
  /**
   * 時間
   * @type {string}
   * @memberof CalendarInnerTimesInner
   */
  time?: string
  /**
   * 枠が利用可能かどうか（企業からの連絡が可能）
   * @type {boolean}
   * @memberof CalendarInnerTimesInner
   */
  is_available?: boolean
}
/**
 * 非公開の求人情報
 * @export
 * @interface ClosedJob
 */
export interface ClosedJob {
  /**
   * 求人ID
   * @type {string}
   * @memberof ClosedJob
   */
  id: string
  /**
   * 求人公開フラグ
   * @type {boolean}
   * @memberof ClosedJob
   */
  is_job_published: boolean
  /**
   * 採用企業名
   * @type {string}
   * @memberof ClosedJob
   */
  recruitment_company_name: string
  /**
   * 求人タイトル
   * @type {string}
   * @memberof ClosedJob
   */
  job_title: string
}
/**
 * お気に入り求人一括登録API
 * @export
 * @interface DettachKeptJobsRequest
 */
export interface DettachKeptJobsRequest {
  /**
   * 解除する求人ID列
   * @type {Array<string>}
   * @memberof DettachKeptJobsRequest
   */
  job_ids: Array<string>
}
/**
 *
 * @export
 * @interface EditAmbitionsRequest
 */
export interface EditAmbitionsRequest {
  /**
   * 転職で実現したいこと(希望順)
   * @type {Array<number>}
   * @memberof EditAmbitionsRequest
   */
  ambitions?: Array<number>
}
/**
 *
 * @export
 * @interface EditContactInformationRequest
 */
export interface EditContactInformationRequest {
  /**
   * emailアドレス（nullで渡せばemailのみを更新できる）
   * @type {string}
   * @memberof EditContactInformationRequest
   */
  email_address: string | null
  /**
   * 電話番号（認証に使用していない場合のみ変更可能。また、nullで渡せばemailのみを更新できる）
   * @type {string}
   * @memberof EditContactInformationRequest
   */
  phone_number: string | null
}
/**
 *
 * @export
 * @interface EditDesiredJobCategoriesRequest
 */
export interface EditDesiredJobCategoriesRequest {
  /**
   * 希望職種
   * @type {Array<number>}
   * @memberof EditDesiredJobCategoriesRequest
   */
  desired_job_categories?: Array<number>
}
/**
 *
 * @export
 * @interface EditDesiredJobStartPeriodsRequest
 */
export interface EditDesiredJobStartPeriodsRequest {
  /**
   * 希望就業開始までの期間マスタID
   * @type {number}
   * @memberof EditDesiredJobStartPeriodsRequest
   */
  desired_job_start_periods_master_id?: number
}
/**
 *
 * @export
 * @interface EditDesiredWorkEnvironmentsRequest
 */
export interface EditDesiredWorkEnvironmentsRequest {
  /**
   * 希望仕事環境
   * @type {Array<number>}
   * @memberof EditDesiredWorkEnvironmentsRequest
   */
  desired_work_environments?: Array<number>
}
/**
 * 希望勤務地の更新API
 * @export
 * @interface EditDesiredWorkLocationRequest
 */
export interface EditDesiredWorkLocationRequest {
  /**
   * 施設名
   * @type {string}
   * @memberof EditDesiredWorkLocationRequest
   */
  facility_name: string
  /**
   * 住所
   * @type {string}
   * @memberof EditDesiredWorkLocationRequest
   */
  address?: string
  /**
   * 都道府県名
   * @type {string}
   * @memberof EditDesiredWorkLocationRequest
   */
  prefecture_name: string
  /**
   * Google Place ID
   * @type {string}
   * @memberof EditDesiredWorkLocationRequest
   */
  place_id: string
  /**
   * 緯度
   * @type {number}
   * @memberof EditDesiredWorkLocationRequest
   */
  lat: number
  /**
   * 経度
   * @type {number}
   * @memberof EditDesiredWorkLocationRequest
   */
  lng: number
  /**
   *
   * @type {GeocoderResult}
   * @memberof EditDesiredWorkLocationRequest
   */
  geocoder_result: GeocoderResult
}
/**
 *
 * @export
 * @interface EditDesiredWorkStylesRequest
 */
export interface EditDesiredWorkStylesRequest {
  /**
   * 希望する働き方
   * @type {Array<number>}
   * @memberof EditDesiredWorkStylesRequest
   */
  desired_work_styles?: Array<number>
}
/**
 *
 * @export
 * @interface EditNotificationSettingsRequest
 */
export interface EditNotificationSettingsRequest {
  /**
   * スカウト送信時間。文字列で0~23を許容
   * @type {string}
   * @memberof EditNotificationSettingsRequest
   */
  scout_delivery_hour?: string
  /**
   * 企業からのスカウトメール受信
   * @type {boolean}
   * @memberof EditNotificationSettingsRequest
   */
  send_email_of_scout_from_company?: boolean
  /**
   * 未完了タスクのリマインドメール受信
   * @type {boolean}
   * @memberof EditNotificationSettingsRequest
   */
  send_email_of_task_reminders?: boolean
  /**
   * 求人案内メール受信
   * @type {boolean}
   * @memberof EditNotificationSettingsRequest
   */
  send_email_of_jobs_information?: boolean
  /**
   * おすすめ情報メール受信
   * @type {boolean}
   * @memberof EditNotificationSettingsRequest
   */
  send_email_of_recommendation?: boolean
  /**
   * いいね求人（スカウト）リマインドメール受信
   * @type {boolean}
   * @memberof EditNotificationSettingsRequest
   */
  send_email_of_good_jobs_reminders?: boolean
  /**
   * 企業からのスカウトLINE受信
   * @type {boolean}
   * @memberof EditNotificationSettingsRequest
   */
  send_line_of_scout_from_company?: boolean
  /**
   * 未完了タスクのリマインドLINE受信
   * @type {boolean}
   * @memberof EditNotificationSettingsRequest
   */
  send_line_of_task_reminders?: boolean
  /**
   * 求人案内LINE受信
   * @type {boolean}
   * @memberof EditNotificationSettingsRequest
   */
  send_line_of_jobs_information?: boolean
  /**
   * おすすめ情報LINE受信
   * @type {boolean}
   * @memberof EditNotificationSettingsRequest
   */
  send_line_of_recommendation?: boolean
  /**
   * いいね求人（スカウト）リマインドLINE受信
   * @type {boolean}
   * @memberof EditNotificationSettingsRequest
   */
  send_line_of_good_jobs_reminders?: boolean
  /**
   * 企業からのスカウトSMS受信
   * @type {boolean}
   * @memberof EditNotificationSettingsRequest
   */
  send_sms_of_scout_from_company?: boolean
  /**
   * 未完了タスクのリマインドSMS受信
   * @type {boolean}
   * @memberof EditNotificationSettingsRequest
   */
  send_sms_of_task_reminders?: boolean
  /**
   * 求人案内SMS受信
   * @type {boolean}
   * @memberof EditNotificationSettingsRequest
   */
  send_sms_of_jobs_information?: boolean
  /**
   * おすすめ情報SMS受信
   * @type {boolean}
   * @memberof EditNotificationSettingsRequest
   */
  send_sms_of_recommendation?: boolean
  /**
   * いいね求人（スカウト）リマインドSMS受信
   * @type {boolean}
   * @memberof EditNotificationSettingsRequest
   */
  send_sms_of_good_jobs_reminders?: boolean
}
/**
 * マイページのプロフィール更新API
 * @export
 * @interface EditProfileRequest
 */
export interface EditProfileRequest {
  /**
   * 氏名（姓）
   * @type {string}
   * @memberof EditProfileRequest
   */
  last_name: string
  /**
   * 氏名(名)
   * @type {string}
   * @memberof EditProfileRequest
   */
  first_name: string
  /**
   * ふりがな(姓)
   * @type {string}
   * @memberof EditProfileRequest
   */
  last_name_kana: string
  /**
   * ふりがな(名)
   * @type {string}
   * @memberof EditProfileRequest
   */
  first_name_kana: string
  /**
   * 生年月日
   * @type {string}
   * @memberof EditProfileRequest
   */
  birthday: string
  /**
   * 性別
   * @type {number}
   * @memberof EditProfileRequest
   */
  gender_master_id: number
  /**
   * 最終学歴
   * @type {number}
   * @memberof EditProfileRequest
   */
  final_education_master_id: number
  /**
   * 現在の雇用形態
   * @type {number}
   * @memberof EditProfileRequest
   */
  current_employment_type_master_id: number
  /**
   * 運転免許有無
   * @type {boolean}
   * @memberof EditProfileRequest
   */
  has_driver_license: boolean
  /**
   * 経験社数(RHFの都合で整数値0が扱いにくいため、文字列で受けてバックエンドのRequestクラスで変換する)
   * @type {string}
   * @memberof EditProfileRequest
   */
  past_employment_company_count: string
  /**
   * 職歴
   * @type {Array<TalentWorkRecordRequest>}
   * @memberof EditProfileRequest
   */
  talent_work_records: Array<TalentWorkRecordRequest>
  /**
   * 現(前)年収
   * @type {number}
   * @memberof EditProfileRequest
   */
  current_annual_salary?: number
  /**
   * 不得意なこと
   * @type {number}
   * @memberof EditProfileRequest
   */
  weakness_master_id: number
  /**
   * やりたくないこと
   * @type {number}
   * @memberof EditProfileRequest
   */
  avoidance_master_id: number
}
/**
 * Geocoding APIのレスポンス
 * @export
 * @interface GeocoderResult
 */
export interface GeocoderResult {
  /**
   * 返されたジオコード要素のタイプを示す文字列の配列
   * @type {Array<string>}
   * @memberof GeocoderResult
   */
  types: Array<string>
  /**
   * フォーマットされた形式の住所
   * @type {string}
   * @memberof GeocoderResult
   */
  formatted_address: string
  /**
   *
   * @type {Array<GeocoderResultAddressComponentsInner>}
   * @memberof GeocoderResult
   */
  address_components: Array<GeocoderResultAddressComponentsInner>
  /**
   * ジオコーダが元のリクエストに対して完全一致を返さなかったが、リクエストされた住所の一部を一致させることができたかどうか
   * @type {boolean}
   * @memberof GeocoderResult
   */
  partial_match?: boolean | null
  /**
   * 場所に関連付けられたプレイスID
   * @type {string}
   * @memberof GeocoderResult
   */
  place_id: string
  /**
   * 郵便番号に含まれるすべての地域を示す文字列の配列
   * @type {Array<string>}
   * @memberof GeocoderResult
   */
  postcode_localities?: Array<string> | null
  /**
   *
   * @type {GeocoderResultGeometry}
   * @memberof GeocoderResult
   */
  geometry: GeocoderResultGeometry
}
/**
 *
 * @export
 * @interface GeocoderResultAddressComponentsInner
 */
export interface GeocoderResultAddressComponentsInner {
  /**
   * 住所コンポーネントの省略形
   * @type {string}
   * @memberof GeocoderResultAddressComponentsInner
   */
  short_name?: string
  /**
   * 住所コンポーネントの完全な文字列
   * @type {string}
   * @memberof GeocoderResultAddressComponentsInner
   */
  long_name?: string
  /**
   * この住所コンポーネントのタイプを示す文字列の配列
   * @type {Array<string>}
   * @memberof GeocoderResultAddressComponentsInner
   */
  types?: Array<string>
  /**
   * 郵便番号に含まれるすべての地域を示す文字列の配列
   * @type {Array<string>}
   * @memberof GeocoderResultAddressComponentsInner
   */
  postcode_localities?: Array<string> | null
}
/**
 *
 * @export
 * @interface GeocoderResultGeometry
 */
export interface GeocoderResultGeometry {
  /**
   *
   * @type {GeocoderResultGeometryLocation}
   * @memberof GeocoderResultGeometry
   */
  location?: GeocoderResultGeometryLocation
  /**
   * 指定した場所に関する追加データ： - ROOFTOP: 正確なジオコーディング - RANGE_INTERPOLATED: 2地点間で補間された近似値 - GEOMETRIC_CENTER: ポリラインやポリゴンの幾何学的中心 - APPROXIMATE: 近似値
   * @type {string}
   * @memberof GeocoderResultGeometry
   */
  location_type?: GeocoderResultGeometryLocationTypeEnum
  /**
   *
   * @type {GeocoderResultGeometryViewport}
   * @memberof GeocoderResultGeometry
   */
  viewport?: GeocoderResultGeometryViewport
  /**
   *
   * @type {GeocoderResultGeometryBounds}
   * @memberof GeocoderResultGeometry
   */
  bounds?: GeocoderResultGeometryBounds | null
}

export const GeocoderResultGeometryLocationTypeEnum = {
  Rooftop: 'ROOFTOP',
  RangeInterpolated: 'RANGE_INTERPOLATED',
  GeometricCenter: 'GEOMETRIC_CENTER',
  Approximate: 'APPROXIMATE',
} as const

export type GeocoderResultGeometryLocationTypeEnum =
  (typeof GeocoderResultGeometryLocationTypeEnum)[keyof typeof GeocoderResultGeometryLocationTypeEnum]

/**
 * 返された結果をすべて含むことができる境界（オプション）
 * @export
 * @interface GeocoderResultGeometryBounds
 */
export interface GeocoderResultGeometryBounds {
  /**
   *
   * @type {number}
   * @memberof GeocoderResultGeometryBounds
   */
  east?: number
  /**
   *
   * @type {number}
   * @memberof GeocoderResultGeometryBounds
   */
  west?: number
  /**
   *
   * @type {number}
   * @memberof GeocoderResultGeometryBounds
   */
  north?: number
  /**
   *
   * @type {number}
   * @memberof GeocoderResultGeometryBounds
   */
  south?: number
}
/**
 * ジオコーディングされた緯度と経度の値
 * @export
 * @interface GeocoderResultGeometryLocation
 */
export interface GeocoderResultGeometryLocation {
  /**
   * 緯度
   * @type {number}
   * @memberof GeocoderResultGeometryLocation
   */
  lat?: number
  /**
   * 経度
   * @type {number}
   * @memberof GeocoderResultGeometryLocation
   */
  lng?: number
}
/**
 * 返された結果の推奨ビューポート
 * @export
 * @interface GeocoderResultGeometryViewport
 */
export interface GeocoderResultGeometryViewport {
  /**
   *
   * @type {number}
   * @memberof GeocoderResultGeometryViewport
   */
  east?: number
  /**
   *
   * @type {number}
   * @memberof GeocoderResultGeometryViewport
   */
  west?: number
  /**
   *
   * @type {number}
   * @memberof GeocoderResultGeometryViewport
   */
  north?: number
  /**
   *
   * @type {number}
   * @memberof GeocoderResultGeometryViewport
   */
  south?: number
}
/**
 *
 * @export
 * @interface GetJob200Response
 */
export interface GetJob200Response {
  /**
   * 求人ID
   * @type {string}
   * @memberof GetJob200Response
   */
  id: string
  /**
   *
   * @type {Array<AddressesByPrefecture>}
   * @memberof GetJob200Response
   */
  addresses_by_prefectures: Array<AddressesByPrefecture>
  /**
   *
   * @type {RecruitmentCompany}
   * @memberof GetJob200Response
   */
  recruitment_company: RecruitmentCompany
  /**
   * 最終更新日
   * @type {string}
   * @memberof GetJob200Response
   */
  ab_modified_at?: string
  /**
   * 内定率
   * @type {number}
   * @memberof GetJob200Response
   */
  job_offer_rate: number
  /**
   * 求人公開フラグ
   * @type {boolean}
   * @memberof GetJob200Response
   */
  is_job_published: boolean
  /**
   *
   * @type {MediumMasterListItem}
   * @memberof GetJob200Response
   */
  job_category?: MediumMasterListItem
  /**
   * 正社員経験なしOKフラグ
   * @type {boolean}
   * @memberof GetJob200Response
   */
  is_allowed_no_prior_full_time_experience: boolean
  /**
   * 上場企業フラグ
   * @type {boolean}
   * @memberof GetJob200Response
   */
  is_listed_company: boolean
  /**
   * 車通勤OKフラグ
   * @type {boolean}
   * @memberof GetJob200Response
   */
  is_allowed_to_commute_by_car: boolean
  /**
   * 転勤なしフラグ
   * @type {boolean}
   * @memberof GetJob200Response
   */
  is_no_need_to_relocate: boolean
  /**
   * 服装自由フラグ
   * @type {boolean}
   * @memberof GetJob200Response
   */
  is_dress_code_free: boolean
  /**
   * 社員寮ありフラグ
   * @type {boolean}
   * @memberof GetJob200Response
   */
  has_dormitory: boolean
  /**
   * 年間休日120日以上フラグ
   * @type {boolean}
   * @memberof GetJob200Response
   */
  is_more_than_120_days_off_per_year: boolean
  /**
   * 土日祝休みフラグ
   * @type {boolean}
   * @memberof GetJob200Response
   */
  has_weekends_and_national_holiday: boolean
  /**
   * 残業時間20時間以内フラグ
   * @type {boolean}
   * @memberof GetJob200Response
   */
  is_overtime_under_20: boolean
  /**
   * 時短勤務可フラグ
   * @type {boolean}
   * @memberof GetJob200Response
   */
  is_available_to_reduce_working_time: boolean
  /**
   * 求人タイトル
   * @type {string}
   * @memberof GetJob200Response
   */
  job_title: string
  /**
   * 年収(上限)
   * @type {number}
   * @memberof GetJob200Response
   */
  max_income: number
  /**
   * 年収(下限)
   * @type {number}
   * @memberof GetJob200Response
   */
  min_income: number
  /**
   * 月収(上限)
   * @type {number}
   * @memberof GetJob200Response
   */
  max_monthly_income: number | null
  /**
   * 月収(下限)
   * @type {number}
   * @memberof GetJob200Response
   */
  min_monthly_income: number | null
  /**
   * 仕事内容
   * @type {string}
   * @memberof GetJob200Response
   */
  explanation_of_job?: string
  /**
   * 仕事の醍醐味
   * @type {string}
   * @memberof GetJob200Response
   */
  description_of_attractiveness?: string | null
  /**
   * 必須要件
   * @type {string}
   * @memberof GetJob200Response
   */
  description_of_entry_requirement?: string | null
  /**
   * 活躍できる経験
   * @type {string}
   * @memberof GetJob200Response
   */
  description_of_expected_experience?: string | null
  /**
   *
   * @type {MediumMasterListItem}
   * @memberof GetJob200Response
   */
  final_education?: MediumMasterListItem
  /**
   *
   * @type {MediumMasterListItem}
   * @memberof GetJob200Response
   */
  driver_license?: MediumMasterListItem
  /**
   * 給与
   * @type {string}
   * @memberof GetJob200Response
   */
  description_of_salary?: string
  /**
   *
   * @type {MediumMasterListItem}
   * @memberof GetJob200Response
   */
  employment_type?: MediumMasterListItem
  /**
   * 勤務時間
   * @type {string}
   * @memberof GetJob200Response
   */
  description_of_working_time?: string
  /**
   * 時短勤務詳細
   * @type {string}
   * @memberof GetJob200Response
   */
  short_time_work_detail?: string | null
  /**
   * 残業時間
   * @type {string}
   * @memberof GetJob200Response
   */
  average_overtime_work_per_month?: string | null
  /**
   * 福利厚生
   * @type {string}
   * @memberof GetJob200Response
   */
  description_of_benefit_system?: string
  /**
   * 年間休日
   * @type {string}
   * @memberof GetJob200Response
   */
  annual_holidays?: string
  /**
   * 休日・休暇
   * @type {string}
   * @memberof GetJob200Response
   */
  description_of_holidays?: string
  /**
   * 試用期間
   * @type {boolean}
   * @memberof GetJob200Response
   */
  has_probationary_period: boolean
  /**
   * 試用期間詳細
   * @type {string}
   * @memberof GetJob200Response
   */
  probation_detail?: string | null
  /**
   * 受動喫煙対策
   * @type {string}
   * @memberof GetJob200Response
   */
  measures_against_passive_smoking?: string
  /**
   * 受動喫煙対策詳細
   * @type {string}
   * @memberof GetJob200Response
   */
  measures_against_passive_smoking_detail?: string | null
  /**
   * ユーザーが応募したことがあるかのフラグ。未ログインの場合は一律NULL。
   * @type {boolean}
   * @memberof GetJob200Response
   */
  is_entered?: boolean | null
  /**
   * ユーザーがお気に入りしているかのフラグ。未ログインの場合は一律NULL。
   * @type {boolean}
   * @memberof GetJob200Response
   */
  is_kept?: boolean | null
  /**
   * 求人まとめ-会社
   * @type {string}
   * @memberof GetJob200Response
   */
  recruitment_summary_company?: string | null
  /**
   * 求人まとめ-仕事内容
   * @type {string}
   * @memberof GetJob200Response
   */
  recruitment_summary_job?: string | null
  /**
   * 求人まとめ-労働条件
   * @type {string}
   * @memberof GetJob200Response
   */
  recruitment_summary_working_condition?: string | null
  /**
   * 直接企業に応募されるかの判定フラグ
   * @type {boolean}
   * @memberof GetJob200Response
   */
  can_direct_apply: boolean
  /**
   * 採用企業名
   * @type {string}
   * @memberof GetJob200Response
   */
  recruitment_company_name: string
}
/**
 * システム全体にわたって表示するアラートの表示設定
 * @export
 * @interface GlobalAlerts
 */
export interface GlobalAlerts {
  /**
   * LINE友達追加を勧める表示
   * @type {boolean}
   * @memberof GlobalAlerts
   */
  showLineFriendNotice: boolean
}
/**
 * システム全体にわたって表示するバッチ件数
 * @export
 * @interface GlobalBadges
 */
export interface GlobalBadges {
  /**
   * キープ件数
   * @type {number}
   * @memberof GlobalBadges
   */
  keep_count: number
  /**
   * パックを除く未読スカウト件数
   * @type {number}
   * @memberof GlobalBadges
   */
  unread_scout_count: number
}
/**
 *
 * @export
 * @interface Inquiry
 */
export interface Inquiry {
  /**
   *
   * @type {InquiryJob}
   * @memberof Inquiry
   */
  job?: InquiryJob
  /**
   *
   * @type {InquiryScout}
   * @memberof Inquiry
   */
  scout?: InquiryScout
  /**
   * 入社報告日時
   * @type {string}
   * @memberof Inquiry
   */
  joining_report_datetime?: string | null
  /**
   *
   * @type {InquiryEntryPrefectureMaster}
   * @memberof Inquiry
   */
  entry_prefecture_master?: InquiryEntryPrefectureMaster
  /**
   * 企業に公開されているかどうか
   * @type {boolean}
   * @memberof Inquiry
   */
  is_visible_for_company?: boolean
}
/**
 * 候補者からの求人・スカウトへの問い合わせの確認情報（確認画面に必要なアトリビュートを備えている）
 * @export
 * @interface InquiryConfirmation
 */
export interface InquiryConfirmation {
  /**
   * 問い合わせ配列（同時にリクエストした問い合わせの集合を表す）
   * @type {Array<Inquiry>}
   * @memberof InquiryConfirmation
   */
  inquiries?: Array<Inquiry>
  /**
   * 問い合わせリクエストID
   * @type {string}
   * @memberof InquiryConfirmation
   */
  request_id?: string
  /**
   * 求職者ID（リレーションは返さない想定。問い合わせ時点スナップショットを優先）
   * @type {string}
   * @memberof InquiryConfirmation
   */
  talent_id: string
  /**
   * 求人への質問（訊きたいこと）のmaster_id
   * @type {number}
   * @memberof InquiryConfirmation
   */
  question_about_job_master_id?: number
  /**
   * 姓（問い合わせ時点）
   * @type {string}
   * @memberof InquiryConfirmation
   */
  last_name: string
  /**
   * 名（問い合わせ時点）
   * @type {string}
   * @memberof InquiryConfirmation
   */
  first_name: string
  /**
   * せい（問い合わせ時点）
   * @type {string}
   * @memberof InquiryConfirmation
   */
  last_name_kana: string
  /**
   * めい（問い合わせ時点）
   * @type {string}
   * @memberof InquiryConfirmation
   */
  first_name_kana: string
  /**
   * 生年月日（問い合わせ時点）
   * @type {string}
   * @memberof InquiryConfirmation
   */
  birthday: string
  /**
   * 性別（問い合わせ時点）
   * @type {string}
   * @memberof InquiryConfirmation
   */
  gender: string
  /**
   * 最終学歴（問い合わせ時点）
   * @type {string}
   * @memberof InquiryConfirmation
   */
  final_education: string
  /**
   * 現在の年収（問い合わせ時点）
   * @type {number}
   * @memberof InquiryConfirmation
   */
  current_annual_salary: number
  /**
   * 登録メールアドレス（問い合わせ時点）
   * @type {string}
   * @memberof InquiryConfirmation
   */
  email_address: string
  /**
   * 経験社数（問い合わせ時点）
   * @type {number}
   * @memberof InquiryConfirmation
   */
  number_of_companies_experienced: number
  /**
   * 運転免許取得状況（問い合わせ時点）
   * @type {string}
   * @memberof InquiryConfirmation
   */
  driver_license_acquisition_status: string
  /**
   * 就業開始時期希望（問い合わせ時点）
   * @type {string}
   * @memberof InquiryConfirmation
   */
  desired_job_start_period: string
  /**
   * 希望職種（問い合わせ時点）
   * @type {string}
   * @memberof InquiryConfirmation
   */
  desired_job_category: string
  /**
   * 希望勤務地_施設名（問い合わせ時点）
   * @type {string}
   * @memberof InquiryConfirmation
   */
  desired_work_location_facility_name: string
  /**
   * 希望勤務地_住所（問い合わせ時点）
   * @type {string}
   * @memberof InquiryConfirmation
   */
  desired_work_location_address: string
  /**
   * 希望勤務地_都道府県（問い合わせ時点）
   * @type {string}
   * @memberof InquiryConfirmation
   */
  desired_work_location_prefecture: string
  /**
   * 現在の雇用形態（問い合わせ時点）
   * @type {string}
   * @memberof InquiryConfirmation
   */
  current_employment_type: string
  /**
   * 電話に出やすい時間として選ばれた時間の集合
   * @type {Array<string>}
   * @memberof InquiryConfirmation
   */
  easy_time_to_answer_calling: Array<string>
  /**
   * 就職で実現したいこと（文字列連結で複数データを1カラム化）（問い合わせ時点）
   * @type {string}
   * @memberof InquiryConfirmation
   */
  talent_ambitions: string
  /**
   * 問い合わせ日時
   * @type {string}
   * @memberof InquiryConfirmation
   */
  created_at: string
}
/**
 *
 * @export
 * @interface InquiryContentInner
 */
export interface InquiryContentInner {
  /**
   * 求人ID
   * @type {string}
   * @memberof InquiryContentInner
   */
  job_id?: string | null
  /**
   * スカウトID
   * @type {string}
   * @memberof InquiryContentInner
   */
  scout_id?: string | null
  /**
   * 勤務地の都道府県ID(求人検索時に都道府県IDを指定している場合のみ)
   * @type {string}
   * @memberof InquiryContentInner
   */
  entry_prefecture_master_id?: string | null
  /**
   * 問い合わせ時にいいねフィードバックを送るか
   * @type {boolean}
   * @memberof InquiryContentInner
   */
  is_good_feedback?: boolean | null
}
/**
 * 応募勤務地マスター
 * @export
 * @interface InquiryEntryPrefectureMaster
 */
export interface InquiryEntryPrefectureMaster {
  /**
   * masterテーブルのID
   * @type {number}
   * @memberof InquiryEntryPrefectureMaster
   */
  id: number
  /**
   * 表示名
   * @type {string}
   * @memberof InquiryEntryPrefectureMaster
   */
  label: string
  /**
   * 順番
   * @type {number}
   * @memberof InquiryEntryPrefectureMaster
   */
  order?: number | null
}
/**
 *
 * @export
 * @interface InquiryJob
 */
export interface InquiryJob {
  /**
   * 求人ID
   * @type {string}
   * @memberof InquiryJob
   */
  id: string
  /**
   *
   * @type {Array<AddressesByPrefecture>}
   * @memberof InquiryJob
   */
  addresses_by_prefectures: Array<AddressesByPrefecture>
  /**
   *
   * @type {RecruitmentCompany}
   * @memberof InquiryJob
   */
  recruitment_company: RecruitmentCompany
  /**
   * 最終更新日
   * @type {string}
   * @memberof InquiryJob
   */
  ab_modified_at?: string
  /**
   * 内定率
   * @type {number}
   * @memberof InquiryJob
   */
  job_offer_rate: number
  /**
   * 求人公開フラグ
   * @type {boolean}
   * @memberof InquiryJob
   */
  is_job_published: boolean
  /**
   *
   * @type {MediumMasterListItem}
   * @memberof InquiryJob
   */
  job_category?: MediumMasterListItem
  /**
   * 正社員経験なしOKフラグ
   * @type {boolean}
   * @memberof InquiryJob
   */
  is_allowed_no_prior_full_time_experience: boolean
  /**
   * 上場企業フラグ
   * @type {boolean}
   * @memberof InquiryJob
   */
  is_listed_company: boolean
  /**
   * 車通勤OKフラグ
   * @type {boolean}
   * @memberof InquiryJob
   */
  is_allowed_to_commute_by_car: boolean
  /**
   * 転勤なしフラグ
   * @type {boolean}
   * @memberof InquiryJob
   */
  is_no_need_to_relocate: boolean
  /**
   * 服装自由フラグ
   * @type {boolean}
   * @memberof InquiryJob
   */
  is_dress_code_free: boolean
  /**
   * 社員寮ありフラグ
   * @type {boolean}
   * @memberof InquiryJob
   */
  has_dormitory: boolean
  /**
   * 年間休日120日以上フラグ
   * @type {boolean}
   * @memberof InquiryJob
   */
  is_more_than_120_days_off_per_year: boolean
  /**
   * 土日祝休みフラグ
   * @type {boolean}
   * @memberof InquiryJob
   */
  has_weekends_and_national_holiday: boolean
  /**
   * 残業時間20時間以内フラグ
   * @type {boolean}
   * @memberof InquiryJob
   */
  is_overtime_under_20: boolean
  /**
   * 時短勤務可フラグ
   * @type {boolean}
   * @memberof InquiryJob
   */
  is_available_to_reduce_working_time: boolean
  /**
   * 求人タイトル
   * @type {string}
   * @memberof InquiryJob
   */
  job_title: string
  /**
   * 年収(上限)
   * @type {number}
   * @memberof InquiryJob
   */
  max_income: number
  /**
   * 年収(下限)
   * @type {number}
   * @memberof InquiryJob
   */
  min_income: number
  /**
   * 月収(上限)
   * @type {number}
   * @memberof InquiryJob
   */
  max_monthly_income: number | null
  /**
   * 月収(下限)
   * @type {number}
   * @memberof InquiryJob
   */
  min_monthly_income: number | null
  /**
   * 仕事内容
   * @type {string}
   * @memberof InquiryJob
   */
  explanation_of_job?: string
  /**
   * 仕事の醍醐味
   * @type {string}
   * @memberof InquiryJob
   */
  description_of_attractiveness?: string | null
  /**
   * 必須要件
   * @type {string}
   * @memberof InquiryJob
   */
  description_of_entry_requirement?: string | null
  /**
   * 活躍できる経験
   * @type {string}
   * @memberof InquiryJob
   */
  description_of_expected_experience?: string | null
  /**
   *
   * @type {MediumMasterListItem}
   * @memberof InquiryJob
   */
  final_education?: MediumMasterListItem
  /**
   *
   * @type {MediumMasterListItem}
   * @memberof InquiryJob
   */
  driver_license?: MediumMasterListItem
  /**
   * 給与
   * @type {string}
   * @memberof InquiryJob
   */
  description_of_salary?: string
  /**
   *
   * @type {MediumMasterListItem}
   * @memberof InquiryJob
   */
  employment_type?: MediumMasterListItem
  /**
   * 勤務時間
   * @type {string}
   * @memberof InquiryJob
   */
  description_of_working_time?: string
  /**
   * 時短勤務詳細
   * @type {string}
   * @memberof InquiryJob
   */
  short_time_work_detail?: string | null
  /**
   * 残業時間
   * @type {string}
   * @memberof InquiryJob
   */
  average_overtime_work_per_month?: string | null
  /**
   * 福利厚生
   * @type {string}
   * @memberof InquiryJob
   */
  description_of_benefit_system?: string
  /**
   * 年間休日
   * @type {string}
   * @memberof InquiryJob
   */
  annual_holidays?: string
  /**
   * 休日・休暇
   * @type {string}
   * @memberof InquiryJob
   */
  description_of_holidays?: string
  /**
   * 試用期間
   * @type {boolean}
   * @memberof InquiryJob
   */
  has_probationary_period: boolean
  /**
   * 試用期間詳細
   * @type {string}
   * @memberof InquiryJob
   */
  probation_detail?: string | null
  /**
   * 受動喫煙対策
   * @type {string}
   * @memberof InquiryJob
   */
  measures_against_passive_smoking?: string
  /**
   * 受動喫煙対策詳細
   * @type {string}
   * @memberof InquiryJob
   */
  measures_against_passive_smoking_detail?: string | null
  /**
   * ユーザーが応募したことがあるかのフラグ。未ログインの場合は一律NULL。
   * @type {boolean}
   * @memberof InquiryJob
   */
  is_entered?: boolean | null
  /**
   * ユーザーがお気に入りしているかのフラグ。未ログインの場合は一律NULL。
   * @type {boolean}
   * @memberof InquiryJob
   */
  is_kept?: boolean | null
  /**
   * 求人まとめ-会社
   * @type {string}
   * @memberof InquiryJob
   */
  recruitment_summary_company?: string | null
  /**
   * 求人まとめ-仕事内容
   * @type {string}
   * @memberof InquiryJob
   */
  recruitment_summary_job?: string | null
  /**
   * 求人まとめ-労働条件
   * @type {string}
   * @memberof InquiryJob
   */
  recruitment_summary_working_condition?: string | null
  /**
   * 直接企業に応募されるかの判定フラグ
   * @type {boolean}
   * @memberof InquiryJob
   */
  can_direct_apply: boolean
}
/**
 * talentの問い合わせ一覧のレスポンス
 * @export
 * @interface InquiryList
 */
export interface InquiryList {
  /**
   *
   * @type {Array<TalentInquiry>}
   * @memberof InquiryList
   */
  data: Array<TalentInquiry>
}
/**
 * 問い合わせリクエスト
 * @export
 * @interface InquiryRequest
 */
export interface InquiryRequest {
  /**
   * 求人への質問（訊きたいこと）のmaster_id
   * @type {number}
   * @memberof InquiryRequest
   */
  question_about_job_master_id: number
  /**
   * 電話に出やすい時間として選ばれた時間の集合
   * @type {Array<string>}
   * @memberof InquiryRequest
   */
  easy_time_to_answer_calling: Array<string>
  /**
   * 問い合わせる求人やスカウトの内容
   * @type {Array<InquiryContentInner>}
   * @memberof InquiryRequest
   */
  inquiries: Array<InquiryContentInner>
}
/**
 *
 * @export
 * @interface InquiryScout
 */
export interface InquiryScout {
  /**
   * スカウトID
   * @type {string}
   * @memberof InquiryScout
   */
  id: string
  /**
   *
   * @type {Job}
   * @memberof InquiryScout
   */
  job: Job
  /**
   * 送信者名
   * @type {string}
   * @memberof InquiryScout
   */
  sender_name: string
  /**
   * アイコン画像URL
   * @type {string}
   * @memberof InquiryScout
   */
  sender_image_url: string
  /**
   * 掲載企業名
   * @type {string}
   * @memberof InquiryScout
   */
  company_name: string
  /**
   *
   * @type {TalentScoutJobSummary}
   * @memberof InquiryScout
   */
  job_summary: TalentScoutJobSummary | null
  /**
   * ひとことメッセージ
   * @type {string}
   * @memberof InquiryScout
   */
  message: string
  /**
   * 閲覧日時
   * @type {string}
   * @memberof InquiryScout
   */
  opened_at: string | null
  /**
   * フィードバック日時
   * @type {string}
   * @memberof InquiryScout
   */
  feedbacked_at?: string | null
  /**
   *
   * @type {TalentScoutScoutFeedbackAnswer}
   * @memberof InquiryScout
   */
  scout_feedback_answer?: TalentScoutScoutFeedbackAnswer | null
  /**
   *
   * @type {TalentScoutScoutFeedbackAnswer}
   * @memberof InquiryScout
   */
  scout_feedback_reason?: TalentScoutScoutFeedbackAnswer | null
  /**
   * 作成日時
   * @type {string}
   * @memberof InquiryScout
   */
  created_at: string
  /**
   * ぜとくまスカウトかどうか
   * @type {boolean}
   * @memberof InquiryScout
   */
  is_system_recommend_job: boolean
  /**
   * 企業がスカウト機能を利用しているかどうか
   * @type {boolean}
   * @memberof InquiryScout
   */
  is_company_scout: boolean
  /**
   * CAスカウトか
   * @type {boolean}
   * @memberof InquiryScout
   */
  is_career_adviser_scout: boolean
  /**
   * 書類選考をスキップするかどうか
   * @type {boolean}
   * @memberof InquiryScout
   */
  skip_document_screening: boolean
  /**
   * 希望勤務地に最も近い勤務地の都道府県ID
   * @type {number}
   * @memberof InquiryScout
   */
  best_work_location_prefecture_master_id?: number
  /**
   * 希望勤務地に最も近い勤務地の都道府県名
   * @type {string}
   * @memberof InquiryScout
   */
  best_work_location_prefecture_label?: string
  /**
   *
   * @type {Address}
   * @memberof InquiryScout
   */
  best_recommended_address?: Address
  /**
   * 転職で実現したいことの評価(優先度順)
   * @type {Array<ScoutEvaluationAmbitions>}
   * @memberof InquiryScout
   */
  scout_evaluation_ambitions?: Array<ScoutEvaluationAmbitions>
  /**
   * 苦手なこと&避けたいことの評価
   * @type {Array<ScoutEvaluationFeature>}
   * @memberof InquiryScout
   */
  scout_evaluation_features?: Array<ScoutEvaluationFeature>
  /**
   * 問い合わせ済みの求人か
   * @type {boolean}
   * @memberof InquiryScout
   */
  is_inquired: boolean
  /**
   * 求人タブは既読か
   * @type {boolean}
   * @memberof InquiryScout
   */
  is_view_job_tab?: boolean
}
/**
 * 求人情報
 * @export
 * @interface Job
 */
export interface Job {
  /**
   * 求人ID
   * @type {string}
   * @memberof Job
   */
  id: string
  /**
   *
   * @type {Array<AddressesByPrefecture>}
   * @memberof Job
   */
  addresses_by_prefectures: Array<AddressesByPrefecture>
  /**
   *
   * @type {RecruitmentCompany}
   * @memberof Job
   */
  recruitment_company: RecruitmentCompany
  /**
   * 最終更新日
   * @type {string}
   * @memberof Job
   */
  ab_modified_at?: string
  /**
   * 内定率
   * @type {number}
   * @memberof Job
   */
  job_offer_rate: number
  /**
   * 求人公開フラグ
   * @type {boolean}
   * @memberof Job
   */
  is_job_published: boolean
  /**
   *
   * @type {MediumMasterListItem}
   * @memberof Job
   */
  job_category?: MediumMasterListItem
  /**
   * 正社員経験なしOKフラグ
   * @type {boolean}
   * @memberof Job
   */
  is_allowed_no_prior_full_time_experience: boolean
  /**
   * 上場企業フラグ
   * @type {boolean}
   * @memberof Job
   */
  is_listed_company: boolean
  /**
   * 車通勤OKフラグ
   * @type {boolean}
   * @memberof Job
   */
  is_allowed_to_commute_by_car: boolean
  /**
   * 転勤なしフラグ
   * @type {boolean}
   * @memberof Job
   */
  is_no_need_to_relocate: boolean
  /**
   * 服装自由フラグ
   * @type {boolean}
   * @memberof Job
   */
  is_dress_code_free: boolean
  /**
   * 社員寮ありフラグ
   * @type {boolean}
   * @memberof Job
   */
  has_dormitory: boolean
  /**
   * 年間休日120日以上フラグ
   * @type {boolean}
   * @memberof Job
   */
  is_more_than_120_days_off_per_year: boolean
  /**
   * 土日祝休みフラグ
   * @type {boolean}
   * @memberof Job
   */
  has_weekends_and_national_holiday: boolean
  /**
   * 残業時間20時間以内フラグ
   * @type {boolean}
   * @memberof Job
   */
  is_overtime_under_20: boolean
  /**
   * 時短勤務可フラグ
   * @type {boolean}
   * @memberof Job
   */
  is_available_to_reduce_working_time: boolean
  /**
   * 求人タイトル
   * @type {string}
   * @memberof Job
   */
  job_title: string
  /**
   * 年収(上限)
   * @type {number}
   * @memberof Job
   */
  max_income: number
  /**
   * 年収(下限)
   * @type {number}
   * @memberof Job
   */
  min_income: number
  /**
   * 月収(上限)
   * @type {number}
   * @memberof Job
   */
  max_monthly_income: number | null
  /**
   * 月収(下限)
   * @type {number}
   * @memberof Job
   */
  min_monthly_income: number | null
  /**
   * 仕事内容
   * @type {string}
   * @memberof Job
   */
  explanation_of_job?: string
  /**
   * 仕事の醍醐味
   * @type {string}
   * @memberof Job
   */
  description_of_attractiveness?: string | null
  /**
   * 必須要件
   * @type {string}
   * @memberof Job
   */
  description_of_entry_requirement?: string | null
  /**
   * 活躍できる経験
   * @type {string}
   * @memberof Job
   */
  description_of_expected_experience?: string | null
  /**
   *
   * @type {MediumMasterListItem}
   * @memberof Job
   */
  final_education?: MediumMasterListItem
  /**
   *
   * @type {MediumMasterListItem}
   * @memberof Job
   */
  driver_license?: MediumMasterListItem
  /**
   * 給与
   * @type {string}
   * @memberof Job
   */
  description_of_salary?: string
  /**
   *
   * @type {MediumMasterListItem}
   * @memberof Job
   */
  employment_type?: MediumMasterListItem
  /**
   * 勤務時間
   * @type {string}
   * @memberof Job
   */
  description_of_working_time?: string
  /**
   * 時短勤務詳細
   * @type {string}
   * @memberof Job
   */
  short_time_work_detail?: string | null
  /**
   * 残業時間
   * @type {string}
   * @memberof Job
   */
  average_overtime_work_per_month?: string | null
  /**
   * 福利厚生
   * @type {string}
   * @memberof Job
   */
  description_of_benefit_system?: string
  /**
   * 年間休日
   * @type {string}
   * @memberof Job
   */
  annual_holidays?: string
  /**
   * 休日・休暇
   * @type {string}
   * @memberof Job
   */
  description_of_holidays?: string
  /**
   * 試用期間
   * @type {boolean}
   * @memberof Job
   */
  has_probationary_period: boolean
  /**
   * 試用期間詳細
   * @type {string}
   * @memberof Job
   */
  probation_detail?: string | null
  /**
   * 受動喫煙対策
   * @type {string}
   * @memberof Job
   */
  measures_against_passive_smoking?: string
  /**
   * 受動喫煙対策詳細
   * @type {string}
   * @memberof Job
   */
  measures_against_passive_smoking_detail?: string | null
  /**
   * ユーザーが応募したことがあるかのフラグ。未ログインの場合は一律NULL。
   * @type {boolean}
   * @memberof Job
   */
  is_entered?: boolean | null
  /**
   * ユーザーがお気に入りしているかのフラグ。未ログインの場合は一律NULL。
   * @type {boolean}
   * @memberof Job
   */
  is_kept?: boolean | null
  /**
   * 求人まとめ-会社
   * @type {string}
   * @memberof Job
   */
  recruitment_summary_company?: string | null
  /**
   * 求人まとめ-仕事内容
   * @type {string}
   * @memberof Job
   */
  recruitment_summary_job?: string | null
  /**
   * 求人まとめ-労働条件
   * @type {string}
   * @memberof Job
   */
  recruitment_summary_working_condition?: string | null
  /**
   * 直接企業に応募されるかの判定フラグ
   * @type {boolean}
   * @memberof Job
   */
  can_direct_apply: boolean
}
/**
 *
 * @export
 * @interface JobCount200Response
 */
export interface JobCount200Response {
  /**
   * 公開ステータスの全ての求人数
   * @type {number}
   * @memberof JobCount200Response
   */
  public_jobs: number
}
/**
 * talentの求人一覧のレスポンス
 * @export
 * @interface JobList
 */
export interface JobList {
  /**
   *
   * @type {Array<Job>}
   * @memberof JobList
   */
  data: Array<Job>
  /**
   *
   * @type {ListMeta}
   * @memberof JobList
   */
  meta: ListMeta
}
/**
 * 求人要約
 * @export
 * @interface JobSummary
 */
export interface JobSummary {
  /**
   * 会社概要
   * @type {string}
   * @memberof JobSummary
   */
  company_overview?: string
  /**
   * 求人内容
   * @type {string}
   * @memberof JobSummary
   */
  job_content?: string
  /**
   * 求人内容
   * @type {string}
   * @memberof JobSummary
   */
  working_conditions?: string
}
/**
 *
 * @export
 * @interface JobTabReadRequest
 */
export interface JobTabReadRequest {
  /**
   * スカウトID
   * @type {string}
   * @memberof JobTabReadRequest
   */
  scout_id: string
}
/**
 *
 * @export
 * @interface LikedReminderClickedRequest
 */
export interface LikedReminderClickedRequest {
  /**
   * 通知履歴ID
   * @type {string}
   * @memberof LikedReminderClickedRequest
   */
  notificationId: string
  /**
   * スカウトID
   * @type {string}
   * @memberof LikedReminderClickedRequest
   */
  scoutId: string
}
/**
 *
 * @export
 * @interface LineLogin200Response
 */
export interface LineLogin200Response {
  /**
   * LINE認証ページへの専用URL
   * @type {string}
   * @memberof LineLogin200Response
   */
  line_login_url: string
}
/**
 *
 * @export
 * @interface ListMeta
 */
export interface ListMeta {
  /**
   *
   * @type {Pagination}
   * @memberof ListMeta
   */
  pagination: Pagination
}
/**
 *
 * @export
 * @interface MeTalent
 */
export interface MeTalent {
  /**
   * ULIDの主キー
   * @type {string}
   * @memberof MeTalent
   */
  id: string
  /**
   * 氏名（姓）
   * @type {string}
   * @memberof MeTalent
   */
  last_name: string
  /**
   * 氏名(名)
   * @type {string}
   * @memberof MeTalent
   */
  first_name: string
  /**
   * ふりがな(姓)
   * @type {string}
   * @memberof MeTalent
   */
  last_name_kana: string
  /**
   * ふりがな(名)
   * @type {string}
   * @memberof MeTalent
   */
  first_name_kana: string
  /**
   * 生年月日
   * @type {string}
   * @memberof MeTalent
   */
  birthday: string
  /**
   *
   * @type {MediumMasterListItem}
   * @memberof MeTalent
   */
  gender: MediumMasterListItem
  /**
   * メールアドレス
   * @type {string}
   * @memberof MeTalent
   */
  email: string
  /**
   * 携帯電話番号
   * @type {string}
   * @memberof MeTalent
   */
  phone_number: string
  /**
   *
   * @type {MediumMasterListItem}
   * @memberof MeTalent
   */
  desired_job_start_periods: MediumMasterListItem
  /**
   *
   * @type {TalentNotificationSettings}
   * @memberof MeTalent
   */
  talent_notification_settings: TalentNotificationSettings
  /**
   *
   * @type {MediumMasterListItem}
   * @memberof MeTalent
   */
  final_education: MediumMasterListItem
  /**
   *
   * @type {MediumMasterListItem}
   * @memberof MeTalent
   */
  current_employment_type: MediumMasterListItem
  /**
   * 運転免許有無
   * @type {boolean}
   * @memberof MeTalent
   */
  has_driver_license: boolean
  /**
   * 希望職種
   * @type {Array<MediumMasterListItem>}
   * @memberof MeTalent
   */
  talent_desired_job_categories: Array<MediumMasterListItem>
  /**
   * 希望仕事環境
   * @type {Array<MediumMasterListItem>}
   * @memberof MeTalent
   */
  talent_desired_work_environments?: Array<MediumMasterListItem>
  /**
   * 希望する働き方
   * @type {Array<MediumMasterListItem>}
   * @memberof MeTalent
   */
  talent_desired_work_styles?: Array<MediumMasterListItem>
  /**
   *
   * @type {TalentDesiredWorkLocation}
   * @memberof MeTalent
   */
  talent_desired_work_location: TalentDesiredWorkLocation
  /**
   * 経験社数
   * @type {number}
   * @memberof MeTalent
   */
  past_employment_company_count: number | null
  /**
   * 現(前)年収
   * @type {number}
   * @memberof MeTalent
   */
  current_annual_salary?: number
  /**
   *
   * @type {MediumMasterListItem}
   * @memberof MeTalent
   */
  weakness?: MediumMasterListItem
  /**
   *
   * @type {MediumMasterListItem}
   * @memberof MeTalent
   */
  avoidance?: MediumMasterListItem
  /**
   * 転職で実現したいこと(希望順)
   * @type {Array<MediumMasterListItem>}
   * @memberof MeTalent
   */
  talent_ambitions: Array<MediumMasterListItem>
  /**
   * 職歴
   * @type {Array<TalentWorkRecord>}
   * @memberof MeTalent
   */
  talent_work_records: Array<TalentWorkRecord>
  /**
   * 求職者の認証設定
   * @type {Array<TalentAuthSetting>}
   * @memberof MeTalent
   */
  talent_auth_settings: Array<TalentAuthSetting>
  /**
   * スカウト受信可能日時
   * @type {string}
   * @memberof MeTalent
   */
  ready_to_receive_scout_at: string | null
  /**
   * 求職者のいいねした求人数
   * @type {number}
   * @memberof MeTalent
   */
  kept_jobs_count: number
  /**
   * パックを除く求職者の未読スカウト数
   * @type {number}
   * @memberof MeTalent
   */
  unread_scout_count: number
  /**
   * アクティブユーザーかどうか（離脱していないか）
   * @type {boolean}
   * @memberof MeTalent
   */
  is_inactive_user: boolean
  /**
   * スカウト数が上限に達していないかどうか
   * @type {boolean}
   * @memberof MeTalent
   */
  is_scout_limit: boolean
}
/**
 *
 * @export
 * @interface MeTalentAllOf
 */
export interface MeTalentAllOf {
  /**
   * 求職者のいいねした求人数
   * @type {number}
   * @memberof MeTalentAllOf
   */
  kept_jobs_count: number
  /**
   * パックを除く求職者の未読スカウト数
   * @type {number}
   * @memberof MeTalentAllOf
   */
  unread_scout_count: number
  /**
   * アクティブユーザーかどうか（離脱していないか）
   * @type {boolean}
   * @memberof MeTalentAllOf
   */
  is_inactive_user: boolean
  /**
   * スカウト数が上限に達していないかどうか
   * @type {boolean}
   * @memberof MeTalentAllOf
   */
  is_scout_limit: boolean
}
/**
 * master情報（selectに入れる値）
 * @export
 * @interface MediumMasterListItem
 */
export interface MediumMasterListItem {
  /**
   * masterテーブルのID
   * @type {number}
   * @memberof MediumMasterListItem
   */
  id: number
  /**
   * 表示名
   * @type {string}
   * @memberof MediumMasterListItem
   */
  label: string
  /**
   * 順番
   * @type {number}
   * @memberof MediumMasterListItem
   */
  order?: number | null
}
/**
 *
 * @export
 * @interface Pack200Response
 */
export interface Pack200Response {
  /**
   *
   * @type {Array<TalentScout>}
   * @memberof Pack200Response
   */
  data?: Array<TalentScout>
}
/**
 * 全てのタグごとのスカウト件数
 * @export
 * @interface PackCount
 */
export interface PackCount {
  /**
   * 仕事環境
   * @type {Array<TagCount>}
   * @memberof PackCount
   */
  desired_work_environments: Array<TagCount>
  /**
   * 働き方
   * @type {Array<TagCount>}
   * @memberof PackCount
   */
  desired_work_styles: Array<TagCount>
  /**
   * 職種
   * @type {Array<TagCount>}
   * @memberof PackCount
   */
  desired_job_categories: Array<TagCount>
}
/**
 *
 * @export
 * @interface Pagination
 */
export interface Pagination {
  /**
   * 総件数
   * @type {number}
   * @memberof Pagination
   */
  total: number
  /**
   * 1ページあたり件数
   * @type {number}
   * @memberof Pagination
   */
  per_page: number
  /**
   * 現在のページの最初の項目の番号
   * @type {number}
   * @memberof Pagination
   */
  first_item_count?: number | null
  /**
   * 現在のページの最後の項目の番号
   * @type {number}
   * @memberof Pagination
   */
  last_item_count?: number | null
  /**
   * 現在のページ数
   * @type {number}
   * @memberof Pagination
   */
  current_page: number
  /**
   * 総ページ数
   * @type {number}
   * @memberof Pagination
   */
  total_pages: number
}
/**
 * 募集企業情報
 * @export
 * @interface RecruitmentCompany
 */
export interface RecruitmentCompany {
  /**
   * AgentBankDBでのrecruitment_company_id
   * @type {number}
   * @memberof RecruitmentCompany
   */
  ab_recruitment_company_id: number
  /**
   * 会社名
   * @type {string}
   * @memberof RecruitmentCompany
   */
  name: string
  /**
   * 本社住所
   * @type {string}
   * @memberof RecruitmentCompany
   */
  address?: string | null
  /**
   * 設立年
   * @type {number}
   * @memberof RecruitmentCompany
   */
  establishment_year: number | null
  /**
   * 設立月
   * @type {number}
   * @memberof RecruitmentCompany
   */
  establishment_month: number | null
  /**
   * 従業員数
   * @type {number}
   * @memberof RecruitmentCompany
   */
  number_of_employees?: number | null
  /**
   * 事業概要
   * @type {string}
   * @memberof RecruitmentCompany
   */
  description_of_business: string
  /**
   * 会社概要
   * @type {string}
   * @memberof RecruitmentCompany
   */
  overview: string | null
  /**
   * 会社HP
   * @type {string}
   * @memberof RecruitmentCompany
   */
  site_url?: string | null
  /**
   * 業界のリスト
   * @type {Array<RecruitmentCompanyIndustry>}
   * @memberof RecruitmentCompany
   */
  recruitment_company_industries: Array<RecruitmentCompanyIndustry>
}
/**
 * 募集企業の業界
 * @export
 * @interface RecruitmentCompanyIndustry
 */
export interface RecruitmentCompanyIndustry {
  /**
   *
   * @type {MediumMasterListItem}
   * @memberof RecruitmentCompanyIndustry
   */
  recruitment_company_industry_lg: MediumMasterListItem
  /**
   *
   * @type {MediumMasterListItem}
   * @memberof RecruitmentCompanyIndustry
   */
  recruitment_company_industry_sm: MediumMasterListItem
}
/**
 *
 * @export
 * @interface RegistrationRequest
 */
export interface RegistrationRequest {
  /**
   * 氏名（姓）
   * @type {string}
   * @memberof RegistrationRequest
   */
  last_name: string
  /**
   * 氏名(名)
   * @type {string}
   * @memberof RegistrationRequest
   */
  first_name: string
  /**
   * ふりがな(姓)
   * @type {string}
   * @memberof RegistrationRequest
   */
  last_name_kana: string
  /**
   * ふりがな(名)
   * @type {string}
   * @memberof RegistrationRequest
   */
  first_name_kana: string
  /**
   * 生年月日
   * @type {string}
   * @memberof RegistrationRequest
   */
  birthday: string
  /**
   * 性別
   * @type {number}
   * @memberof RegistrationRequest
   */
  gender_master_id: number
  /**
   * メールアドレス
   * @type {string}
   * @memberof RegistrationRequest
   */
  email: string
  /**
   * 携帯電話番号
   * @type {string}
   * @memberof RegistrationRequest
   */
  phone_number: string
  /**
   * 最終学歴
   * @type {number}
   * @memberof RegistrationRequest
   */
  final_education_master_id: number
  /**
   * 現在の雇用形態
   * @type {number}
   * @memberof RegistrationRequest
   */
  current_employment_type_master_id: number
  /**
   * 希望就業開始までの期間マスタID
   * @type {number}
   * @memberof RegistrationRequest
   */
  desired_job_start_periods_master_id: number
  /**
   * 運転免許有無
   * @type {boolean}
   * @memberof RegistrationRequest
   */
  has_driver_license?: boolean
  /**
   * 施設名
   * @type {string}
   * @memberof RegistrationRequest
   */
  facility_name: string
  /**
   * 住所
   * @type {string}
   * @memberof RegistrationRequest
   */
  address?: string
  /**
   * 都道府県名
   * @type {string}
   * @memberof RegistrationRequest
   */
  prefecture_name: string
  /**
   * Google Place ID
   * @type {string}
   * @memberof RegistrationRequest
   */
  place_id: string
  /**
   * 緯度
   * @type {number}
   * @memberof RegistrationRequest
   */
  lat: number
  /**
   * 経度
   * @type {number}
   * @memberof RegistrationRequest
   */
  lng: number
  /**
   *
   * @type {GeocoderResult}
   * @memberof RegistrationRequest
   */
  geocoder_result: GeocoderResult
  /**
   * utm_campaign
   * @type {string}
   * @memberof RegistrationRequest
   */
  utm_campaign?: string
  /**
   * utm_content
   * @type {string}
   * @memberof RegistrationRequest
   */
  utm_content?: string
  /**
   * utm_medium
   * @type {string}
   * @memberof RegistrationRequest
   */
  utm_medium?: string
  /**
   * utm_source
   * @type {string}
   * @memberof RegistrationRequest
   */
  utm_source?: string
  /**
   * utm_term
   * @type {string}
   * @memberof RegistrationRequest
   */
  utm_term?: string
}
/**
 * 転職で実現したいことの評価(優先度順)
 * @export
 * @interface ScoutEvaluationAmbitions
 */
export interface ScoutEvaluationAmbitions {
  /**
   * 転職で実現したいこと表示名
   * @type {string}
   * @memberof ScoutEvaluationAmbitions
   */
  talent_ambitions_name: string
  /**
   * スコア
   * @type {number}
   * @memberof ScoutEvaluationAmbitions
   */
  score: number
  /**
   * 本文を行ごとに配列化したもの
   * @type {Array<string>}
   * @memberof ScoutEvaluationAmbitions
   */
  text: Array<string>
}
/**
 * 苦手なこと&避けたいことの評価
 * @export
 * @interface ScoutEvaluationFeature
 */
export interface ScoutEvaluationFeature {
  /**
   * 転職者に合う特徴の表示名
   * @type {string}
   * @memberof ScoutEvaluationFeature
   */
  feature_name: string
  /**
   * 転職者に合う特徴の評価理由
   * @type {string}
   * @memberof ScoutEvaluationFeature
   */
  text: string
}
/**
 * スカウトフィードバックリクエスト
 * @export
 * @interface ScoutFeedbackRequest
 */
export interface ScoutFeedbackRequest {
  /**
   * スカウトID
   * @type {string}
   * @memberof ScoutFeedbackRequest
   */
  scout_id: string
  /**
   * フィードバック回答
   * @type {number}
   * @memberof ScoutFeedbackRequest
   */
  feedback_answer: ScoutFeedbackRequestFeedbackAnswerEnum
  /**
   * フィードバック理由
   * @type {number}
   * @memberof ScoutFeedbackRequest
   */
  feedback_reason?: ScoutFeedbackRequestFeedbackReasonEnum
}

export const ScoutFeedbackRequestFeedbackAnswerEnum = {
  /**
   * 興味なし
   */
  NOT_INTERESTED: 1,
  /**
   * いいね！
   */
  LIKED: 2,
} as const

export type ScoutFeedbackRequestFeedbackAnswerEnum =
  (typeof ScoutFeedbackRequestFeedbackAnswerEnum)[keyof typeof ScoutFeedbackRequestFeedbackAnswerEnum]
export const ScoutFeedbackRequestFeedbackReasonEnum = {
  /**
   * 給与
   */
  SALARY: 1,
  /**
   * プライベート
   */
  PRIVATE: 2,
  /**
   * スキルアップ
   */
  SKILL_UP: 3,
  /**
   * 会社の安定性
   */
  STABILITY: 4,
  /**
   * 仕事内容
   */
  JOB_DESCRIPTION: 5,
  /**
   * 勤務地
   */
  WORK_LOCATION: 6,
} as const

export type ScoutFeedbackRequestFeedbackReasonEnum =
  (typeof ScoutFeedbackRequestFeedbackReasonEnum)[keyof typeof ScoutFeedbackRequestFeedbackReasonEnum]

/**
 * レコメンドテスト用のスカウトリスト
 * @export
 * @interface ScoutList
 */
export interface ScoutList {
  /**
   *
   * @type {Array<TalentScout>}
   * @memberof ScoutList
   */
  data: Array<TalentScout>
  /**
   *
   * @type {string}
   * @memberof ScoutList
   */
  scout_message_header: string
}
/**
 * 求職者に届いたスカウトを種別ごとにページネーションして渡す。指定したタイプのみの中身を埋めて返す。
 * @export
 * @interface Scouts
 */
export interface Scouts {
  /**
   *
   * @type {Array<TalentScout>}
   * @memberof Scouts
   */
  liked: Array<TalentScout> | null
  /**
   *
   * @type {Array<TalentScout>}
   * @memberof Scouts
   */
  not_interested?: Array<TalentScout> | null
  /**
   *
   * @type {Array<TalentScout>}
   * @memberof Scouts
   */
  unread: Array<TalentScout> | null
}
/**
 *
 * @export
 * @interface Station
 */
export interface Station {
  /**
   * AgentBankDBでのstation.id
   * @type {number}
   * @memberof Station
   */
  ab_station_id: number
  /**
   * 駅からの時間
   * @type {string}
   * @memberof Station
   */
  time_from_station_text: string
}
/**
 * タグごとのスカウト件数
 * @export
 * @interface TagCount
 */
export interface TagCount {
  /**
   * タグID
   * @type {number}
   * @memberof TagCount
   */
  tag_id: number
  /**
   * スカウト件数
   * @type {number}
   * @memberof TagCount
   */
  count: number
}
/**
 * Talentのユーザー情報のレスポンス
 * @export
 * @interface Talent
 */
export interface Talent {
  /**
   * ULIDの主キー
   * @type {string}
   * @memberof Talent
   */
  id: string
  /**
   * 氏名（姓）
   * @type {string}
   * @memberof Talent
   */
  last_name: string
  /**
   * 氏名(名)
   * @type {string}
   * @memberof Talent
   */
  first_name: string
  /**
   * ふりがな(姓)
   * @type {string}
   * @memberof Talent
   */
  last_name_kana: string
  /**
   * ふりがな(名)
   * @type {string}
   * @memberof Talent
   */
  first_name_kana: string
  /**
   * 生年月日
   * @type {string}
   * @memberof Talent
   */
  birthday: string
  /**
   *
   * @type {MediumMasterListItem}
   * @memberof Talent
   */
  gender: MediumMasterListItem
  /**
   * メールアドレス
   * @type {string}
   * @memberof Talent
   */
  email: string
  /**
   * 携帯電話番号
   * @type {string}
   * @memberof Talent
   */
  phone_number: string
  /**
   *
   * @type {MediumMasterListItem}
   * @memberof Talent
   */
  desired_job_start_periods: MediumMasterListItem
  /**
   *
   * @type {TalentNotificationSettings}
   * @memberof Talent
   */
  talent_notification_settings: TalentNotificationSettings
  /**
   *
   * @type {MediumMasterListItem}
   * @memberof Talent
   */
  final_education: MediumMasterListItem
  /**
   *
   * @type {MediumMasterListItem}
   * @memberof Talent
   */
  current_employment_type: MediumMasterListItem
  /**
   * 運転免許有無
   * @type {boolean}
   * @memberof Talent
   */
  has_driver_license: boolean
  /**
   * 希望職種
   * @type {Array<MediumMasterListItem>}
   * @memberof Talent
   */
  talent_desired_job_categories: Array<MediumMasterListItem>
  /**
   * 希望仕事環境
   * @type {Array<MediumMasterListItem>}
   * @memberof Talent
   */
  talent_desired_work_environments?: Array<MediumMasterListItem>
  /**
   * 希望する働き方
   * @type {Array<MediumMasterListItem>}
   * @memberof Talent
   */
  talent_desired_work_styles?: Array<MediumMasterListItem>
  /**
   *
   * @type {TalentDesiredWorkLocation}
   * @memberof Talent
   */
  talent_desired_work_location: TalentDesiredWorkLocation
  /**
   * 経験社数
   * @type {number}
   * @memberof Talent
   */
  past_employment_company_count: number | null
  /**
   * 現(前)年収
   * @type {number}
   * @memberof Talent
   */
  current_annual_salary?: number
  /**
   *
   * @type {MediumMasterListItem}
   * @memberof Talent
   */
  weakness?: MediumMasterListItem
  /**
   *
   * @type {MediumMasterListItem}
   * @memberof Talent
   */
  avoidance?: MediumMasterListItem
  /**
   * 転職で実現したいこと(希望順)
   * @type {Array<MediumMasterListItem>}
   * @memberof Talent
   */
  talent_ambitions: Array<MediumMasterListItem>
  /**
   * 職歴
   * @type {Array<TalentWorkRecord>}
   * @memberof Talent
   */
  talent_work_records: Array<TalentWorkRecord>
  /**
   * 求職者の認証設定
   * @type {Array<TalentAuthSetting>}
   * @memberof Talent
   */
  talent_auth_settings: Array<TalentAuthSetting>
  /**
   * スカウト受信可能日時
   * @type {string}
   * @memberof Talent
   */
  ready_to_receive_scout_at: string | null
}
/**
 * Talentの通知設定
 * @export
 * @interface TalentAuthSetting
 */
export interface TalentAuthSetting {
  /**
   * 求職者ID
   * @type {string}
   * @memberof TalentAuthSetting
   */
  talent_id: string
  /**
   * 認証ID
   * @type {string}
   * @memberof TalentAuthSetting
   */
  auth_id: string
  /**
   * 認証タイプ
   * @type {string}
   * @memberof TalentAuthSetting
   */
  auth_type: TalentAuthSettingAuthTypeEnum
}

export const TalentAuthSettingAuthTypeEnum = {
  FirebaseSms: 'firebase_sms',
  Line: 'line',
} as const

export type TalentAuthSettingAuthTypeEnum =
  (typeof TalentAuthSettingAuthTypeEnum)[keyof typeof TalentAuthSettingAuthTypeEnum]

/**
 * Talentの希望勤務地
 * @export
 * @interface TalentDesiredWorkLocation
 */
export interface TalentDesiredWorkLocation {
  /**
   * 求職者ID
   * @type {string}
   * @memberof TalentDesiredWorkLocation
   */
  talent_id: string
  /**
   * 施設名
   * @type {string}
   * @memberof TalentDesiredWorkLocation
   */
  facility_name: string
  /**
   * 住所
   * @type {string}
   * @memberof TalentDesiredWorkLocation
   */
  address?: string
  /**
   *
   * @type {MediumMasterListItem}
   * @memberof TalentDesiredWorkLocation
   */
  prefecture: MediumMasterListItem
  /**
   * Google Place ID
   * @type {string}
   * @memberof TalentDesiredWorkLocation
   */
  place_id: string
  /**
   * 緯度
   * @type {number}
   * @memberof TalentDesiredWorkLocation
   */
  lat: number
  /**
   * 経度
   * @type {number}
   * @memberof TalentDesiredWorkLocation
   */
  lng: number
}
/**
 * talentの問い合わせ一覧に表示される項目
 * @export
 * @interface TalentInquiry
 */
export interface TalentInquiry {
  /**
   * 問い合わせID
   * @type {string}
   * @memberof TalentInquiry
   */
  id: string
  /**
   * 求人ID
   * @type {string}
   * @memberof TalentInquiry
   */
  job_id: string
  /**
   * スカウトID
   * @type {string}
   * @memberof TalentInquiry
   */
  scout_id?: string | null
  /**
   * 求人企業名
   * @type {string}
   * @memberof TalentInquiry
   */
  ab_recruitment_company: string
  /**
   * 求人タイトル
   * @type {string}
   * @memberof TalentInquiry
   */
  job_title: string
  /**
   * スカウトフラグ
   * @type {boolean}
   * @memberof TalentInquiry
   */
  is_normal_scouted: boolean
  /**
   * 書類選考スキップフラグ
   * @type {boolean}
   * @memberof TalentInquiry
   */
  can_skip_document_screening: boolean
  /**
   * 給与レンジ(〇〇万円〜〇〇万円)
   * @type {string}
   * @memberof TalentInquiry
   */
  income_range: string
  /**
   * 勤務地
   * @type {string}
   * @memberof TalentInquiry
   */
  prefecture?: string | null
  /**
   * 職種
   * @type {string}
   * @memberof TalentInquiry
   */
  job_category: string
  /**
   * 年間休日120日以上フラグ
   * @type {boolean}
   * @memberof TalentInquiry
   */
  is_more_than_120_days_off_per_year: boolean
  /**
   * 残業時間20時間以内フラグ
   * @type {boolean}
   * @memberof TalentInquiry
   */
  is_overtime_under_20: boolean
  /**
   * 問い合わせ日時
   * @type {string}
   * @memberof TalentInquiry
   */
  created_at: string
  /**
   * 求人への質問（訊きたいこと）のmaster_id
   * @type {number}
   * @memberof TalentInquiry
   */
  question_about_job_master_id?: number
  /**
   * 電話に出やすい時間として選ばれた時間の集合
   * @type {Array<string>}
   * @memberof TalentInquiry
   */
  easy_time_to_answer_callings?: Array<string>
}
/**
 * Talentの通知設定
 * @export
 * @interface TalentNotificationSettings
 */
export interface TalentNotificationSettings {
  /**
   * 求職者ID
   * @type {string}
   * @memberof TalentNotificationSettings
   */
  talent_id: string
  /**
   * スカウト配信時間を文字列型の整数にしたもの
   * @type {string}
   * @memberof TalentNotificationSettings
   */
  scout_delivery_hour: string
  /**
   * 企業からのスカウトメールを送るか
   * @type {boolean}
   * @memberof TalentNotificationSettings
   */
  send_email_of_scout_from_company: boolean
  /**
   * タスクリマインドメールを送るか
   * @type {boolean}
   * @memberof TalentNotificationSettings
   */
  send_email_of_task_reminders: boolean
  /**
   * 求人情報メールを送るか
   * @type {boolean}
   * @memberof TalentNotificationSettings
   */
  send_email_of_jobs_information: boolean
  /**
   * レコメンド情報メールを送るか
   * @type {boolean}
   * @memberof TalentNotificationSettings
   */
  send_email_of_recommendation: boolean
  /**
   * いいね求人（スカウト）リマインドメールを送るか
   * @type {boolean}
   * @memberof TalentNotificationSettings
   */
  send_email_of_good_jobs_reminders: boolean
  /**
   * 企業からのスカウトLINEを送るか
   * @type {boolean}
   * @memberof TalentNotificationSettings
   */
  send_line_of_scout_from_company: boolean
  /**
   * タスクリマインドLINEを送るか
   * @type {boolean}
   * @memberof TalentNotificationSettings
   */
  send_line_of_task_reminders: boolean
  /**
   * 求人情報LINEを送るか
   * @type {boolean}
   * @memberof TalentNotificationSettings
   */
  send_line_of_jobs_information: boolean
  /**
   * レコメンド情報LINEを送るか
   * @type {boolean}
   * @memberof TalentNotificationSettings
   */
  send_line_of_recommendation: boolean
  /**
   * いいね求人（スカウト）リマインドLINEを送るか
   * @type {boolean}
   * @memberof TalentNotificationSettings
   */
  send_line_of_good_jobs_reminders: boolean
  /**
   * LINE友だちか
   * @type {boolean}
   * @memberof TalentNotificationSettings
   */
  is_line_friend: boolean
  /**
   * 企業からのスカウトSMS受信
   * @type {boolean}
   * @memberof TalentNotificationSettings
   */
  send_sms_of_scout_from_company: boolean
  /**
   * 未完了タスクのリマインドSMS受信
   * @type {boolean}
   * @memberof TalentNotificationSettings
   */
  send_sms_of_task_reminders: boolean
  /**
   * 求人案内SMS受信
   * @type {boolean}
   * @memberof TalentNotificationSettings
   */
  send_sms_of_jobs_information: boolean
  /**
   * おすすめ情報SMS受信
   * @type {boolean}
   * @memberof TalentNotificationSettings
   */
  send_sms_of_recommendation: boolean
  /**
   * いいね求人（スカウト）リマインドSMSを送るか
   * @type {boolean}
   * @memberof TalentNotificationSettings
   */
  send_sms_of_good_jobs_reminders: boolean
  /**
   * スカウト配信時間がユーザー設定か
   * @type {boolean}
   * @memberof TalentNotificationSettings
   */
  is_scout_delivery_hour_user_set: boolean
}
/**
 * 求職者に届いたスカウト単体、求職者に可視性を与えなくて良い項目
 * @export
 * @interface TalentScout
 */
export interface TalentScout {
  /**
   * スカウトID
   * @type {string}
   * @memberof TalentScout
   */
  id: string
  /**
   *
   * @type {Job}
   * @memberof TalentScout
   */
  job: Job
  /**
   * 送信者名
   * @type {string}
   * @memberof TalentScout
   */
  sender_name: string
  /**
   * アイコン画像URL
   * @type {string}
   * @memberof TalentScout
   */
  sender_image_url: string
  /**
   * 掲載企業名
   * @type {string}
   * @memberof TalentScout
   */
  company_name: string
  /**
   *
   * @type {TalentScoutJobSummary}
   * @memberof TalentScout
   */
  job_summary: TalentScoutJobSummary | null
  /**
   * ひとことメッセージ
   * @type {string}
   * @memberof TalentScout
   */
  message: string
  /**
   * 閲覧日時
   * @type {string}
   * @memberof TalentScout
   */
  opened_at: string | null
  /**
   * フィードバック日時
   * @type {string}
   * @memberof TalentScout
   */
  feedbacked_at?: string | null
  /**
   *
   * @type {TalentScoutScoutFeedbackAnswer}
   * @memberof TalentScout
   */
  scout_feedback_answer?: TalentScoutScoutFeedbackAnswer | null
  /**
   *
   * @type {TalentScoutScoutFeedbackAnswer}
   * @memberof TalentScout
   */
  scout_feedback_reason?: TalentScoutScoutFeedbackAnswer | null
  /**
   * 作成日時
   * @type {string}
   * @memberof TalentScout
   */
  created_at: string
  /**
   * ぜとくまスカウトかどうか
   * @type {boolean}
   * @memberof TalentScout
   */
  is_system_recommend_job: boolean
  /**
   * 企業がスカウト機能を利用しているかどうか
   * @type {boolean}
   * @memberof TalentScout
   */
  is_company_scout: boolean
  /**
   * CAスカウトか
   * @type {boolean}
   * @memberof TalentScout
   */
  is_career_adviser_scout: boolean
  /**
   * 書類選考をスキップするかどうか
   * @type {boolean}
   * @memberof TalentScout
   */
  skip_document_screening: boolean
  /**
   * 希望勤務地に最も近い勤務地の都道府県ID
   * @type {number}
   * @memberof TalentScout
   */
  best_work_location_prefecture_master_id?: number
  /**
   * 希望勤務地に最も近い勤務地の都道府県名
   * @type {string}
   * @memberof TalentScout
   */
  best_work_location_prefecture_label?: string
  /**
   *
   * @type {Address}
   * @memberof TalentScout
   */
  best_recommended_address?: Address
  /**
   * 転職で実現したいことの評価(優先度順)
   * @type {Array<ScoutEvaluationAmbitions>}
   * @memberof TalentScout
   */
  scout_evaluation_ambitions?: Array<ScoutEvaluationAmbitions>
  /**
   * 苦手なこと&避けたいことの評価
   * @type {Array<ScoutEvaluationFeature>}
   * @memberof TalentScout
   */
  scout_evaluation_features?: Array<ScoutEvaluationFeature>
  /**
   * 問い合わせ済みの求人か
   * @type {boolean}
   * @memberof TalentScout
   */
  is_inquired: boolean
  /**
   * 求人タブは既読か
   * @type {boolean}
   * @memberof TalentScout
   */
  is_view_job_tab?: boolean
}
/**
 *
 * @export
 * @interface TalentScoutJobSummary
 */
export interface TalentScoutJobSummary {
  /**
   * 会社概要
   * @type {string}
   * @memberof TalentScoutJobSummary
   */
  company_overview?: string
  /**
   * 求人内容
   * @type {string}
   * @memberof TalentScoutJobSummary
   */
  job_content?: string
  /**
   * 求人内容
   * @type {string}
   * @memberof TalentScoutJobSummary
   */
  working_conditions?: string
}
/**
 *
 * @export
 * @interface TalentScoutScoutFeedbackAnswer
 */
export interface TalentScoutScoutFeedbackAnswer {
  /**
   * masterテーブルのID
   * @type {number}
   * @memberof TalentScoutScoutFeedbackAnswer
   */
  id: number
  /**
   * 表示名
   * @type {string}
   * @memberof TalentScoutScoutFeedbackAnswer
   */
  label: string
  /**
   * 順番
   * @type {number}
   * @memberof TalentScoutScoutFeedbackAnswer
   */
  order?: number | null
}
/**
 * 求職者に届いた動画スカウト単体、求職者に可視性を与えなくて良い項目
 * @export
 * @interface TalentScoutVideo
 */
export interface TalentScoutVideo {
  /**
   *
   * @type {TalentScout}
   * @memberof TalentScoutVideo
   */
  scout: TalentScout
  /**
   *
   * @type {TalentScoutVideoMovie}
   * @memberof TalentScoutVideo
   */
  movie?: TalentScoutVideoMovie
}
/**
 *
 * @export
 * @interface TalentScoutVideoMovie
 */
export interface TalentScoutVideoMovie {
  /**
   * 動画URL
   * @type {string}
   * @memberof TalentScoutVideoMovie
   */
  movie_url: string
  /**
   * 一言メッセージ
   * @type {string}
   * @memberof TalentScoutVideoMovie
   */
  message: string
}
/**
 * 職歴
 * @export
 * @interface TalentWorkRecord
 */
export interface TalentWorkRecord {
  /**
   *
   * @type {MediumMasterListItem}
   * @memberof TalentWorkRecord
   */
  job_category_master: MediumMasterListItem
  /**
   *
   * @type {MediumMasterListItem}
   * @memberof TalentWorkRecord
   */
  employment_type_master: MediumMasterListItem
  /**
   * 勤続年数
   * @type {number}
   * @memberof TalentWorkRecord
   */
  years_of_service: number
  /**
   * 現職フラグ
   * @type {boolean}
   * @memberof TalentWorkRecord
   */
  is_incumbent: boolean
}
/**
 * 職歴
 * @export
 * @interface TalentWorkRecordRequest
 */
export interface TalentWorkRecordRequest {
  /**
   * 職種ID
   * @type {number}
   * @memberof TalentWorkRecordRequest
   */
  job_category_master_id: number
  /**
   * 雇用形態ID
   * @type {number}
   * @memberof TalentWorkRecordRequest
   */
  employment_type_master_id: number
  /**
   * 勤続年数
   * @type {number}
   * @memberof TalentWorkRecordRequest
   */
  years_of_service: number
  /**
   * 現職フラグ
   * @type {boolean}
   * @memberof TalentWorkRecordRequest
   */
  is_incumbent: boolean
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary SMSの認証方法を追加するAPI。id_tokenをAuthorizationヘッダに含める
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addSmsLoginMethod: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/auth/add_sms_login_method`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Talentユーザー削除API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authDeleteUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/auth/delete`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary システム全体にわたって表示するアラートの表示設定を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchGlobalAlerts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/auth/fetch_global_alerts`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary システム全体にわたって表示するバッチ件数を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchGlobalBadges: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/auth/fetch_global_badges`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Talentユーザー情報取得API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/auth/me`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary LINE認証方法追加フロー開始時に、セッション値管理のために叩くAPI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    lineAddLoginMethod: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/auth/line/add_login_method`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary LINE認証フロー開始時に、セッション値管理のために叩くAPI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    lineLogin: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/auth/line/login`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary ユーザーがLINE認証を経由して新規登録する際に最初に叩くAPI
     * @param {RegistrationRequest} registrationRequest 新規登録時時に必要なパラメーター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    lineRegistration: async (
      registrationRequest: RegistrationRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'registrationRequest' is not null or undefined
      assertParamExists('lineRegistration', 'registrationRequest', registrationRequest)
      const localVarPath = `/auth/line/registration`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        registrationRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary セッション開始用のログインエンドポイント、firebaseでの認証完了後、id tokenをAuthorizationヘッダに含める）
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    login: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/auth/login`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary ログアウトAPI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logout: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/auth/logout`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary ユーザーが新規登録するAPI
     * @param {RegistrationRequest} registrationRequest 新規登録時時に必要なパラメーター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registration: async (
      registrationRequest: RegistrationRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'registrationRequest' is not null or undefined
      assertParamExists('registration', 'registrationRequest', registrationRequest)
      const localVarPath = `/auth/registration`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        registrationRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Laravelのセッションを開始・初期化するためのAPI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionInitialize: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/auth/session_initialize`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary SMSの認証方法を追加するAPI。id_tokenをAuthorizationヘッダに含める
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addSmsLoginMethod(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addSmsLoginMethod(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Talentユーザー削除API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authDeleteUser(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authDeleteUser(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary システム全体にわたって表示するアラートの表示設定を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchGlobalAlerts(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlobalAlerts>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.fetchGlobalAlerts(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary システム全体にわたって表示するバッチ件数を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchGlobalBadges(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlobalBadges>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.fetchGlobalBadges(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Talentユーザー情報取得API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMe(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeTalent>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMe(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary LINE認証方法追加フロー開始時に、セッション値管理のために叩くAPI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async lineAddLoginMethod(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LineLogin200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.lineAddLoginMethod(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary LINE認証フロー開始時に、セッション値管理のために叩くAPI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async lineLogin(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LineLogin200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.lineLogin(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary ユーザーがLINE認証を経由して新規登録する際に最初に叩くAPI
     * @param {RegistrationRequest} registrationRequest 新規登録時時に必要なパラメーター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async lineRegistration(
      registrationRequest: RegistrationRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LineLogin200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.lineRegistration(
        registrationRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary セッション開始用のログインエンドポイント、firebaseでの認証完了後、id tokenをAuthorizationヘッダに含める）
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async login(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.login(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary ログアウトAPI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async logout(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.logout(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary ユーザーが新規登録するAPI
     * @param {RegistrationRequest} registrationRequest 新規登録時時に必要なパラメーター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async registration(
      registrationRequest: RegistrationRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.registration(
        registrationRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Laravelのセッションを開始・初期化するためのAPI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sessionInitialize(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sessionInitialize(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AuthApiFp(configuration)
  return {
    /**
     *
     * @summary SMSの認証方法を追加するAPI。id_tokenをAuthorizationヘッダに含める
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addSmsLoginMethod(options?: any): AxiosPromise<void> {
      return localVarFp.addSmsLoginMethod(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Talentユーザー削除API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authDeleteUser(options?: any): AxiosPromise<void> {
      return localVarFp.authDeleteUser(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary システム全体にわたって表示するアラートの表示設定を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchGlobalAlerts(options?: any): AxiosPromise<GlobalAlerts> {
      return localVarFp.fetchGlobalAlerts(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary システム全体にわたって表示するバッチ件数を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchGlobalBadges(options?: any): AxiosPromise<GlobalBadges> {
      return localVarFp.fetchGlobalBadges(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Talentユーザー情報取得API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMe(options?: any): AxiosPromise<MeTalent> {
      return localVarFp.getMe(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary LINE認証方法追加フロー開始時に、セッション値管理のために叩くAPI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    lineAddLoginMethod(options?: any): AxiosPromise<LineLogin200Response> {
      return localVarFp.lineAddLoginMethod(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary LINE認証フロー開始時に、セッション値管理のために叩くAPI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    lineLogin(options?: any): AxiosPromise<LineLogin200Response> {
      return localVarFp.lineLogin(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary ユーザーがLINE認証を経由して新規登録する際に最初に叩くAPI
     * @param {RegistrationRequest} registrationRequest 新規登録時時に必要なパラメーター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    lineRegistration(
      registrationRequest: RegistrationRequest,
      options?: any,
    ): AxiosPromise<LineLogin200Response> {
      return localVarFp
        .lineRegistration(registrationRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary セッション開始用のログインエンドポイント、firebaseでの認証完了後、id tokenをAuthorizationヘッダに含める）
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    login(options?: any): AxiosPromise<string> {
      return localVarFp.login(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary ログアウトAPI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logout(options?: any): AxiosPromise<void> {
      return localVarFp.logout(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary ユーザーが新規登録するAPI
     * @param {RegistrationRequest} registrationRequest 新規登録時時に必要なパラメーター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registration(registrationRequest: RegistrationRequest, options?: any): AxiosPromise<string> {
      return localVarFp
        .registration(registrationRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Laravelのセッションを開始・初期化するためのAPI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionInitialize(options?: any): AxiosPromise<void> {
      return localVarFp.sessionInitialize(options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
  /**
   *
   * @summary SMSの認証方法を追加するAPI。id_tokenをAuthorizationヘッダに含める
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public addSmsLoginMethod(options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .addSmsLoginMethod(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Talentユーザー削除API
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authDeleteUser(options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .authDeleteUser(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary システム全体にわたって表示するアラートの表示設定を取得する
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public fetchGlobalAlerts(options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .fetchGlobalAlerts(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary システム全体にわたって表示するバッチ件数を取得する
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public fetchGlobalBadges(options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .fetchGlobalBadges(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Talentユーザー情報取得API
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public getMe(options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .getMe(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary LINE認証方法追加フロー開始時に、セッション値管理のために叩くAPI
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public lineAddLoginMethod(options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .lineAddLoginMethod(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary LINE認証フロー開始時に、セッション値管理のために叩くAPI
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public lineLogin(options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .lineLogin(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary ユーザーがLINE認証を経由して新規登録する際に最初に叩くAPI
   * @param {RegistrationRequest} registrationRequest 新規登録時時に必要なパラメーター
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public lineRegistration(registrationRequest: RegistrationRequest, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .lineRegistration(registrationRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary セッション開始用のログインエンドポイント、firebaseでの認証完了後、id tokenをAuthorizationヘッダに含める）
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public login(options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .login(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary ログアウトAPI
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public logout(options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .logout(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary ユーザーが新規登録するAPI
   * @param {RegistrationRequest} registrationRequest 新規登録時時に必要なパラメーター
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public registration(registrationRequest: RegistrationRequest, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .registration(registrationRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Laravelのセッションを開始・初期化するためのAPI
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public sessionInitialize(options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .sessionInitialize(options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * InquiryApi - axios parameter creator
 * @export
 */
export const InquiryApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary 求職者が求人・スカウトへの問い合わせを行うAPI
     * @param {InquiryRequest} inquiryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createInquiry: async (
      inquiryRequest: InquiryRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'inquiryRequest' is not null or undefined
      assertParamExists('createInquiry', 'inquiryRequest', inquiryRequest)
      const localVarPath = `/inquiry`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        inquiryRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 求職者が求人・スカウトへの問い合わせ一覧取得を行うAPI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchInquiriesList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/inquiry/list`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 企業から求職者に連絡する候補日時を返却するAPI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchInquiryCalendar: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/inquiry/calendar`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * InquiryApi - functional programming interface
 * @export
 */
export const InquiryApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = InquiryApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary 求職者が求人・スカウトへの問い合わせを行うAPI
     * @param {InquiryRequest} inquiryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createInquiry(
      inquiryRequest: InquiryRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InquiryConfirmation>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createInquiry(
        inquiryRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary 求職者が求人・スカウトへの問い合わせ一覧取得を行うAPI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchInquiriesList(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InquiryList>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.fetchInquiriesList(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary 企業から求職者に連絡する候補日時を返却するAPI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchInquiryCalendar(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarInner>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.fetchInquiryCalendar(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * InquiryApi - factory interface
 * @export
 */
export const InquiryApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = InquiryApiFp(configuration)
  return {
    /**
     *
     * @summary 求職者が求人・スカウトへの問い合わせを行うAPI
     * @param {InquiryRequest} inquiryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createInquiry(
      inquiryRequest: InquiryRequest,
      options?: any,
    ): AxiosPromise<InquiryConfirmation> {
      return localVarFp
        .createInquiry(inquiryRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 求職者が求人・スカウトへの問い合わせ一覧取得を行うAPI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchInquiriesList(options?: any): AxiosPromise<InquiryList> {
      return localVarFp.fetchInquiriesList(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 企業から求職者に連絡する候補日時を返却するAPI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchInquiryCalendar(options?: any): AxiosPromise<Array<CalendarInner>> {
      return localVarFp.fetchInquiryCalendar(options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * InquiryApi - object-oriented interface
 * @export
 * @class InquiryApi
 * @extends {BaseAPI}
 */
export class InquiryApi extends BaseAPI {
  /**
   *
   * @summary 求職者が求人・スカウトへの問い合わせを行うAPI
   * @param {InquiryRequest} inquiryRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InquiryApi
   */
  public createInquiry(inquiryRequest: InquiryRequest, options?: AxiosRequestConfig) {
    return InquiryApiFp(this.configuration)
      .createInquiry(inquiryRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 求職者が求人・スカウトへの問い合わせ一覧取得を行うAPI
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InquiryApi
   */
  public fetchInquiriesList(options?: AxiosRequestConfig) {
    return InquiryApiFp(this.configuration)
      .fetchInquiriesList(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 企業から求職者に連絡する候補日時を返却するAPI
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InquiryApi
   */
  public fetchInquiryCalendar(options?: AxiosRequestConfig) {
    return InquiryApiFp(this.configuration)
      .fetchInquiryCalendar(options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * JobApi - axios parameter creator
 * @export
 */
export const JobApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary お気に入り求人一括登録API
     * @param {BulkKeepJobRequest} [bulkKeepJobRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkKeepJob: async (
      bulkKeepJobRequest?: BulkKeepJobRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/job/keep/bulk`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        bulkKeepJobRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary お気に入り求人解除API
     * @param {DettachKeptJobsRequest} [dettachKeptJobsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dettachKeptJobs: async (
      dettachKeptJobsRequest?: DettachKeptJobsRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/job/keep/dettach`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        dettachKeptJobsRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 求人をID指定で一括取得するAPI
     * @param {Array<string>} jobIds 求人ID列
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchJobsByIds: async (
      jobIds: Array<string>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobIds' is not null or undefined
      assertParamExists('fetchJobsByIds', 'jobIds', jobIds)
      const localVarPath = `/job/fetch`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (jobIds) {
        localVarQueryParameter['job_ids[]'] = jobIds
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 求人を単体取得するAPI
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getJob: async (jobId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('getJob', 'jobId', jobId)
      const localVarPath = `/job/{job_id}`.replace(
        `{${'job_id'}}`,
        encodeURIComponent(String(jobId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 求人一覧を取得するAPI
     * @param {number} [page] ページ番号
     * @param {Array<number>} [workLocationPrefecture] 勤務地（都道府県）
     * @param {Array<number>} [jobCategory] 職種
     * @param {Array<number>} [particularCondition] こだわり条件
     * @param {number} [companyId] 企業ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getJobList: async (
      page?: number,
      workLocationPrefecture?: Array<number>,
      jobCategory?: Array<number>,
      particularCondition?: Array<number>,
      companyId?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/job`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (page !== undefined) {
        localVarQueryParameter['page'] = page
      }

      if (workLocationPrefecture) {
        localVarQueryParameter['work_location_prefecture[]'] = workLocationPrefecture
      }

      if (jobCategory) {
        localVarQueryParameter['job_category[]'] = jobCategory
      }

      if (particularCondition) {
        localVarQueryParameter['particular_condition[]'] = particularCondition
      }

      if (companyId !== undefined) {
        localVarQueryParameter['company_id'] = companyId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary お気に入り求人一覧を取得するAPI
     * @param {number} [page] ページ番号
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getKeptJobs: async (page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/job/keep`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (page !== undefined) {
        localVarQueryParameter['page'] = page
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 求人を単体取得するAPI
     * @param {string} jobId
     * @param {string} masterPrefectureId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLimitedWorkLocationJob: async (
      jobId: string,
      masterPrefectureId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('getLimitedWorkLocationJob', 'jobId', jobId)
      // verify required parameter 'masterPrefectureId' is not null or undefined
      assertParamExists('getLimitedWorkLocationJob', 'masterPrefectureId', masterPrefectureId)
      const localVarPath = `/job/{job_id}/{master_prefecture_id}`
        .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)))
        .replace(`{${'master_prefecture_id'}}`, encodeURIComponent(String(masterPrefectureId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * JobApi - functional programming interface
 * @export
 */
export const JobApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = JobApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary お気に入り求人一括登録API
     * @param {BulkKeepJobRequest} [bulkKeepJobRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bulkKeepJob(
      bulkKeepJobRequest?: BulkKeepJobRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bulkKeepJob(
        bulkKeepJobRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary お気に入り求人解除API
     * @param {DettachKeptJobsRequest} [dettachKeptJobsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dettachKeptJobs(
      dettachKeptJobsRequest?: DettachKeptJobsRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dettachKeptJobs(
        dettachKeptJobsRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary 求人をID指定で一括取得するAPI
     * @param {Array<string>} jobIds 求人ID列
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchJobsByIds(
      jobIds: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobList>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.fetchJobsByIds(jobIds, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary 求人を単体取得するAPI
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getJob(
      jobId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetJob200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getJob(jobId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary 求人一覧を取得するAPI
     * @param {number} [page] ページ番号
     * @param {Array<number>} [workLocationPrefecture] 勤務地（都道府県）
     * @param {Array<number>} [jobCategory] 職種
     * @param {Array<number>} [particularCondition] こだわり条件
     * @param {number} [companyId] 企業ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getJobList(
      page?: number,
      workLocationPrefecture?: Array<number>,
      jobCategory?: Array<number>,
      particularCondition?: Array<number>,
      companyId?: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobList>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getJobList(
        page,
        workLocationPrefecture,
        jobCategory,
        particularCondition,
        companyId,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary お気に入り求人一覧を取得するAPI
     * @param {number} [page] ページ番号
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getKeptJobs(
      page?: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobList>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getKeptJobs(page, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary 求人を単体取得するAPI
     * @param {string} jobId
     * @param {string} masterPrefectureId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLimitedWorkLocationJob(
      jobId: string,
      masterPrefectureId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetJob200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLimitedWorkLocationJob(
        jobId,
        masterPrefectureId,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * JobApi - factory interface
 * @export
 */
export const JobApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = JobApiFp(configuration)
  return {
    /**
     *
     * @summary お気に入り求人一括登録API
     * @param {BulkKeepJobRequest} [bulkKeepJobRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkKeepJob(bulkKeepJobRequest?: BulkKeepJobRequest, options?: any): AxiosPromise<void> {
      return localVarFp
        .bulkKeepJob(bulkKeepJobRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary お気に入り求人解除API
     * @param {DettachKeptJobsRequest} [dettachKeptJobsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dettachKeptJobs(
      dettachKeptJobsRequest?: DettachKeptJobsRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .dettachKeptJobs(dettachKeptJobsRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 求人をID指定で一括取得するAPI
     * @param {Array<string>} jobIds 求人ID列
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchJobsByIds(jobIds: Array<string>, options?: any): AxiosPromise<JobList> {
      return localVarFp.fetchJobsByIds(jobIds, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 求人を単体取得するAPI
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getJob(jobId: string, options?: any): AxiosPromise<GetJob200Response> {
      return localVarFp.getJob(jobId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 求人一覧を取得するAPI
     * @param {number} [page] ページ番号
     * @param {Array<number>} [workLocationPrefecture] 勤務地（都道府県）
     * @param {Array<number>} [jobCategory] 職種
     * @param {Array<number>} [particularCondition] こだわり条件
     * @param {number} [companyId] 企業ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getJobList(
      page?: number,
      workLocationPrefecture?: Array<number>,
      jobCategory?: Array<number>,
      particularCondition?: Array<number>,
      companyId?: number,
      options?: any,
    ): AxiosPromise<JobList> {
      return localVarFp
        .getJobList(
          page,
          workLocationPrefecture,
          jobCategory,
          particularCondition,
          companyId,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary お気に入り求人一覧を取得するAPI
     * @param {number} [page] ページ番号
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getKeptJobs(page?: number, options?: any): AxiosPromise<JobList> {
      return localVarFp.getKeptJobs(page, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 求人を単体取得するAPI
     * @param {string} jobId
     * @param {string} masterPrefectureId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLimitedWorkLocationJob(
      jobId: string,
      masterPrefectureId: string,
      options?: any,
    ): AxiosPromise<GetJob200Response> {
      return localVarFp
        .getLimitedWorkLocationJob(jobId, masterPrefectureId, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * JobApi - object-oriented interface
 * @export
 * @class JobApi
 * @extends {BaseAPI}
 */
export class JobApi extends BaseAPI {
  /**
   *
   * @summary お気に入り求人一括登録API
   * @param {BulkKeepJobRequest} [bulkKeepJobRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApi
   */
  public bulkKeepJob(bulkKeepJobRequest?: BulkKeepJobRequest, options?: AxiosRequestConfig) {
    return JobApiFp(this.configuration)
      .bulkKeepJob(bulkKeepJobRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary お気に入り求人解除API
   * @param {DettachKeptJobsRequest} [dettachKeptJobsRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApi
   */
  public dettachKeptJobs(
    dettachKeptJobsRequest?: DettachKeptJobsRequest,
    options?: AxiosRequestConfig,
  ) {
    return JobApiFp(this.configuration)
      .dettachKeptJobs(dettachKeptJobsRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 求人をID指定で一括取得するAPI
   * @param {Array<string>} jobIds 求人ID列
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApi
   */
  public fetchJobsByIds(jobIds: Array<string>, options?: AxiosRequestConfig) {
    return JobApiFp(this.configuration)
      .fetchJobsByIds(jobIds, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 求人を単体取得するAPI
   * @param {string} jobId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApi
   */
  public getJob(jobId: string, options?: AxiosRequestConfig) {
    return JobApiFp(this.configuration)
      .getJob(jobId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 求人一覧を取得するAPI
   * @param {number} [page] ページ番号
   * @param {Array<number>} [workLocationPrefecture] 勤務地（都道府県）
   * @param {Array<number>} [jobCategory] 職種
   * @param {Array<number>} [particularCondition] こだわり条件
   * @param {number} [companyId] 企業ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApi
   */
  public getJobList(
    page?: number,
    workLocationPrefecture?: Array<number>,
    jobCategory?: Array<number>,
    particularCondition?: Array<number>,
    companyId?: number,
    options?: AxiosRequestConfig,
  ) {
    return JobApiFp(this.configuration)
      .getJobList(
        page,
        workLocationPrefecture,
        jobCategory,
        particularCondition,
        companyId,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary お気に入り求人一覧を取得するAPI
   * @param {number} [page] ページ番号
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApi
   */
  public getKeptJobs(page?: number, options?: AxiosRequestConfig) {
    return JobApiFp(this.configuration)
      .getKeptJobs(page, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 求人を単体取得するAPI
   * @param {string} jobId
   * @param {string} masterPrefectureId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApi
   */
  public getLimitedWorkLocationJob(
    jobId: string,
    masterPrefectureId: string,
    options?: AxiosRequestConfig,
  ) {
    return JobApiFp(this.configuration)
      .getLimitedWorkLocationJob(jobId, masterPrefectureId, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * MypageApi - axios parameter creator
 * @export
 */
export const MypageApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary ユーザーの「転職で実現したいこと」を変更するAPI
     * @param {EditAmbitionsRequest} editAmbitionsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editAmbitions: async (
      editAmbitionsRequest: EditAmbitionsRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'editAmbitionsRequest' is not null or undefined
      assertParamExists('editAmbitions', 'editAmbitionsRequest', editAmbitionsRequest)
      const localVarPath = `/mypage/edit/ambitions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        editAmbitionsRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary ユーザーの連絡先情報を変更するAPI
     * @param {EditContactInformationRequest} [editContactInformationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editContactInformation: async (
      editContactInformationRequest?: EditContactInformationRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/mypage/edit/contact_information`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        editContactInformationRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary ユーザーの希望職種を変更するAPI
     * @param {EditDesiredJobCategoriesRequest} editDesiredJobCategoriesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editDesiredJobCategories: async (
      editDesiredJobCategoriesRequest: EditDesiredJobCategoriesRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'editDesiredJobCategoriesRequest' is not null or undefined
      assertParamExists(
        'editDesiredJobCategories',
        'editDesiredJobCategoriesRequest',
        editDesiredJobCategoriesRequest,
      )
      const localVarPath = `/mypage/edit/desired_job_categories`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        editDesiredJobCategoriesRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary ユーザーの就業開始希望時期を変更するAPI
     * @param {EditDesiredJobStartPeriodsRequest} editDesiredJobStartPeriodsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editDesiredJobStartPeriods: async (
      editDesiredJobStartPeriodsRequest: EditDesiredJobStartPeriodsRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'editDesiredJobStartPeriodsRequest' is not null or undefined
      assertParamExists(
        'editDesiredJobStartPeriods',
        'editDesiredJobStartPeriodsRequest',
        editDesiredJobStartPeriodsRequest,
      )
      const localVarPath = `/mypage/edit/desired_job_start_periods`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        editDesiredJobStartPeriodsRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary ユーザーの希望仕事環境を変更するAPI
     * @param {EditDesiredWorkEnvironmentsRequest} editDesiredWorkEnvironmentsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editDesiredWorkEnvironments: async (
      editDesiredWorkEnvironmentsRequest: EditDesiredWorkEnvironmentsRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'editDesiredWorkEnvironmentsRequest' is not null or undefined
      assertParamExists(
        'editDesiredWorkEnvironments',
        'editDesiredWorkEnvironmentsRequest',
        editDesiredWorkEnvironmentsRequest,
      )
      const localVarPath = `/mypage/edit/desired_work_environments`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        editDesiredWorkEnvironmentsRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary ユーザーの希望勤務地を変更するAPI
     * @param {EditDesiredWorkLocationRequest} editDesiredWorkLocationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editDesiredWorkLocation: async (
      editDesiredWorkLocationRequest: EditDesiredWorkLocationRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'editDesiredWorkLocationRequest' is not null or undefined
      assertParamExists(
        'editDesiredWorkLocation',
        'editDesiredWorkLocationRequest',
        editDesiredWorkLocationRequest,
      )
      const localVarPath = `/mypage/edit/desired_work_location`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        editDesiredWorkLocationRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary ユーザーの希望する働き方を変更するAPI
     * @param {EditDesiredWorkStylesRequest} editDesiredWorkStylesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editDesiredWorkStyles: async (
      editDesiredWorkStylesRequest: EditDesiredWorkStylesRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'editDesiredWorkStylesRequest' is not null or undefined
      assertParamExists(
        'editDesiredWorkStyles',
        'editDesiredWorkStylesRequest',
        editDesiredWorkStylesRequest,
      )
      const localVarPath = `/mypage/edit/desired_work_styles`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        editDesiredWorkStylesRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary ユーザーの通知設定を編集するAPI
     * @param {EditNotificationSettingsRequest} editNotificationSettingsRequest ユーザーの通知設定変更時に必要なパラメーター。更新したいフィールドのみをキーに入れる。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editNotificationSettings: async (
      editNotificationSettingsRequest: EditNotificationSettingsRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'editNotificationSettingsRequest' is not null or undefined
      assertParamExists(
        'editNotificationSettings',
        'editNotificationSettingsRequest',
        editNotificationSettingsRequest,
      )
      const localVarPath = `/mypage/edit/notification_settings`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        editNotificationSettingsRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary ユーザーのプロフィール情報を編集するAPI
     * @param {EditProfileRequest} editProfileRequest ユーザーのプロフィール情報編集時に必要なパラメーター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editProfile: async (
      editProfileRequest: EditProfileRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'editProfileRequest' is not null or undefined
      assertParamExists('editProfile', 'editProfileRequest', editProfileRequest)
      const localVarPath = `/mypage/edit/profile`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        editProfileRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * MypageApi - functional programming interface
 * @export
 */
export const MypageApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = MypageApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary ユーザーの「転職で実現したいこと」を変更するAPI
     * @param {EditAmbitionsRequest} editAmbitionsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async editAmbitions(
      editAmbitionsRequest: EditAmbitionsRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.editAmbitions(
        editAmbitionsRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary ユーザーの連絡先情報を変更するAPI
     * @param {EditContactInformationRequest} [editContactInformationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async editContactInformation(
      editContactInformationRequest?: EditContactInformationRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.editContactInformation(
        editContactInformationRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary ユーザーの希望職種を変更するAPI
     * @param {EditDesiredJobCategoriesRequest} editDesiredJobCategoriesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async editDesiredJobCategories(
      editDesiredJobCategoriesRequest: EditDesiredJobCategoriesRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.editDesiredJobCategories(
        editDesiredJobCategoriesRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary ユーザーの就業開始希望時期を変更するAPI
     * @param {EditDesiredJobStartPeriodsRequest} editDesiredJobStartPeriodsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async editDesiredJobStartPeriods(
      editDesiredJobStartPeriodsRequest: EditDesiredJobStartPeriodsRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.editDesiredJobStartPeriods(
        editDesiredJobStartPeriodsRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary ユーザーの希望仕事環境を変更するAPI
     * @param {EditDesiredWorkEnvironmentsRequest} editDesiredWorkEnvironmentsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async editDesiredWorkEnvironments(
      editDesiredWorkEnvironmentsRequest: EditDesiredWorkEnvironmentsRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.editDesiredWorkEnvironments(
        editDesiredWorkEnvironmentsRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary ユーザーの希望勤務地を変更するAPI
     * @param {EditDesiredWorkLocationRequest} editDesiredWorkLocationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async editDesiredWorkLocation(
      editDesiredWorkLocationRequest: EditDesiredWorkLocationRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.editDesiredWorkLocation(
        editDesiredWorkLocationRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary ユーザーの希望する働き方を変更するAPI
     * @param {EditDesiredWorkStylesRequest} editDesiredWorkStylesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async editDesiredWorkStyles(
      editDesiredWorkStylesRequest: EditDesiredWorkStylesRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.editDesiredWorkStyles(
        editDesiredWorkStylesRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary ユーザーの通知設定を編集するAPI
     * @param {EditNotificationSettingsRequest} editNotificationSettingsRequest ユーザーの通知設定変更時に必要なパラメーター。更新したいフィールドのみをキーに入れる。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async editNotificationSettings(
      editNotificationSettingsRequest: EditNotificationSettingsRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.editNotificationSettings(
        editNotificationSettingsRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary ユーザーのプロフィール情報を編集するAPI
     * @param {EditProfileRequest} editProfileRequest ユーザーのプロフィール情報編集時に必要なパラメーター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async editProfile(
      editProfileRequest: EditProfileRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.editProfile(
        editProfileRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * MypageApi - factory interface
 * @export
 */
export const MypageApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = MypageApiFp(configuration)
  return {
    /**
     *
     * @summary ユーザーの「転職で実現したいこと」を変更するAPI
     * @param {EditAmbitionsRequest} editAmbitionsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editAmbitions(editAmbitionsRequest: EditAmbitionsRequest, options?: any): AxiosPromise<void> {
      return localVarFp
        .editAmbitions(editAmbitionsRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary ユーザーの連絡先情報を変更するAPI
     * @param {EditContactInformationRequest} [editContactInformationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editContactInformation(
      editContactInformationRequest?: EditContactInformationRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .editContactInformation(editContactInformationRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary ユーザーの希望職種を変更するAPI
     * @param {EditDesiredJobCategoriesRequest} editDesiredJobCategoriesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editDesiredJobCategories(
      editDesiredJobCategoriesRequest: EditDesiredJobCategoriesRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .editDesiredJobCategories(editDesiredJobCategoriesRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary ユーザーの就業開始希望時期を変更するAPI
     * @param {EditDesiredJobStartPeriodsRequest} editDesiredJobStartPeriodsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editDesiredJobStartPeriods(
      editDesiredJobStartPeriodsRequest: EditDesiredJobStartPeriodsRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .editDesiredJobStartPeriods(editDesiredJobStartPeriodsRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary ユーザーの希望仕事環境を変更するAPI
     * @param {EditDesiredWorkEnvironmentsRequest} editDesiredWorkEnvironmentsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editDesiredWorkEnvironments(
      editDesiredWorkEnvironmentsRequest: EditDesiredWorkEnvironmentsRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .editDesiredWorkEnvironments(editDesiredWorkEnvironmentsRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary ユーザーの希望勤務地を変更するAPI
     * @param {EditDesiredWorkLocationRequest} editDesiredWorkLocationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editDesiredWorkLocation(
      editDesiredWorkLocationRequest: EditDesiredWorkLocationRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .editDesiredWorkLocation(editDesiredWorkLocationRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary ユーザーの希望する働き方を変更するAPI
     * @param {EditDesiredWorkStylesRequest} editDesiredWorkStylesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editDesiredWorkStyles(
      editDesiredWorkStylesRequest: EditDesiredWorkStylesRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .editDesiredWorkStyles(editDesiredWorkStylesRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary ユーザーの通知設定を編集するAPI
     * @param {EditNotificationSettingsRequest} editNotificationSettingsRequest ユーザーの通知設定変更時に必要なパラメーター。更新したいフィールドのみをキーに入れる。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editNotificationSettings(
      editNotificationSettingsRequest: EditNotificationSettingsRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .editNotificationSettings(editNotificationSettingsRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary ユーザーのプロフィール情報を編集するAPI
     * @param {EditProfileRequest} editProfileRequest ユーザーのプロフィール情報編集時に必要なパラメーター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editProfile(editProfileRequest: EditProfileRequest, options?: any): AxiosPromise<void> {
      return localVarFp
        .editProfile(editProfileRequest, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * MypageApi - object-oriented interface
 * @export
 * @class MypageApi
 * @extends {BaseAPI}
 */
export class MypageApi extends BaseAPI {
  /**
   *
   * @summary ユーザーの「転職で実現したいこと」を変更するAPI
   * @param {EditAmbitionsRequest} editAmbitionsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MypageApi
   */
  public editAmbitions(editAmbitionsRequest: EditAmbitionsRequest, options?: AxiosRequestConfig) {
    return MypageApiFp(this.configuration)
      .editAmbitions(editAmbitionsRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary ユーザーの連絡先情報を変更するAPI
   * @param {EditContactInformationRequest} [editContactInformationRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MypageApi
   */
  public editContactInformation(
    editContactInformationRequest?: EditContactInformationRequest,
    options?: AxiosRequestConfig,
  ) {
    return MypageApiFp(this.configuration)
      .editContactInformation(editContactInformationRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary ユーザーの希望職種を変更するAPI
   * @param {EditDesiredJobCategoriesRequest} editDesiredJobCategoriesRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MypageApi
   */
  public editDesiredJobCategories(
    editDesiredJobCategoriesRequest: EditDesiredJobCategoriesRequest,
    options?: AxiosRequestConfig,
  ) {
    return MypageApiFp(this.configuration)
      .editDesiredJobCategories(editDesiredJobCategoriesRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary ユーザーの就業開始希望時期を変更するAPI
   * @param {EditDesiredJobStartPeriodsRequest} editDesiredJobStartPeriodsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MypageApi
   */
  public editDesiredJobStartPeriods(
    editDesiredJobStartPeriodsRequest: EditDesiredJobStartPeriodsRequest,
    options?: AxiosRequestConfig,
  ) {
    return MypageApiFp(this.configuration)
      .editDesiredJobStartPeriods(editDesiredJobStartPeriodsRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary ユーザーの希望仕事環境を変更するAPI
   * @param {EditDesiredWorkEnvironmentsRequest} editDesiredWorkEnvironmentsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MypageApi
   */
  public editDesiredWorkEnvironments(
    editDesiredWorkEnvironmentsRequest: EditDesiredWorkEnvironmentsRequest,
    options?: AxiosRequestConfig,
  ) {
    return MypageApiFp(this.configuration)
      .editDesiredWorkEnvironments(editDesiredWorkEnvironmentsRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary ユーザーの希望勤務地を変更するAPI
   * @param {EditDesiredWorkLocationRequest} editDesiredWorkLocationRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MypageApi
   */
  public editDesiredWorkLocation(
    editDesiredWorkLocationRequest: EditDesiredWorkLocationRequest,
    options?: AxiosRequestConfig,
  ) {
    return MypageApiFp(this.configuration)
      .editDesiredWorkLocation(editDesiredWorkLocationRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary ユーザーの希望する働き方を変更するAPI
   * @param {EditDesiredWorkStylesRequest} editDesiredWorkStylesRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MypageApi
   */
  public editDesiredWorkStyles(
    editDesiredWorkStylesRequest: EditDesiredWorkStylesRequest,
    options?: AxiosRequestConfig,
  ) {
    return MypageApiFp(this.configuration)
      .editDesiredWorkStyles(editDesiredWorkStylesRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary ユーザーの通知設定を編集するAPI
   * @param {EditNotificationSettingsRequest} editNotificationSettingsRequest ユーザーの通知設定変更時に必要なパラメーター。更新したいフィールドのみをキーに入れる。
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MypageApi
   */
  public editNotificationSettings(
    editNotificationSettingsRequest: EditNotificationSettingsRequest,
    options?: AxiosRequestConfig,
  ) {
    return MypageApiFp(this.configuration)
      .editNotificationSettings(editNotificationSettingsRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary ユーザーのプロフィール情報を編集するAPI
   * @param {EditProfileRequest} editProfileRequest ユーザーのプロフィール情報編集時に必要なパラメーター
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MypageApi
   */
  public editProfile(editProfileRequest: EditProfileRequest, options?: AxiosRequestConfig) {
    return MypageApiFp(this.configuration)
      .editProfile(editProfileRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * PlaygroundApi - axios parameter creator
 * @export
 */
export const PlaygroundApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary レコメンド求人を確認するテスト用API
     * @param {boolean} [isExcludeFeedbacked] FB済み以外洗い替えをするか否かのフラグ
     * @param {boolean} [shouldDelete] 洗い替えをするか否かのフラグ
     * @param {number} [wgPersonality] wg_personality
     * @param {number} [wgWantTo] wg_want_to
     * @param {number} [wgDistance] wg_distance
     * @param {number} [wgDesiredJobCategories] wg_desired_job_categories
     * @param {number} [wgDesiredWorkEnvironments] wg_desired_work_environments
     * @param {number} [wgDesiredWorkStyles] wg_desired_work_styles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecommendationJob: async (
      isExcludeFeedbacked?: boolean,
      shouldDelete?: boolean,
      wgPersonality?: number,
      wgWantTo?: number,
      wgDistance?: number,
      wgDesiredJobCategories?: number,
      wgDesiredWorkEnvironments?: number,
      wgDesiredWorkStyles?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/playground/job-recommendation`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (isExcludeFeedbacked !== undefined) {
        localVarQueryParameter['is_exclude_feedbacked'] = isExcludeFeedbacked
      }

      if (shouldDelete !== undefined) {
        localVarQueryParameter['should_delete'] = shouldDelete
      }

      if (wgPersonality !== undefined) {
        localVarQueryParameter['wg_personality'] = wgPersonality
      }

      if (wgWantTo !== undefined) {
        localVarQueryParameter['wg_want_to'] = wgWantTo
      }

      if (wgDistance !== undefined) {
        localVarQueryParameter['wg_distance'] = wgDistance
      }

      if (wgDesiredJobCategories !== undefined) {
        localVarQueryParameter['wg_desired_job_categories'] = wgDesiredJobCategories
      }

      if (wgDesiredWorkEnvironments !== undefined) {
        localVarQueryParameter['wg_desired_work_environments'] = wgDesiredWorkEnvironments
      }

      if (wgDesiredWorkStyles !== undefined) {
        localVarQueryParameter['wg_desired_work_styles'] = wgDesiredWorkStyles
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * PlaygroundApi - functional programming interface
 * @export
 */
export const PlaygroundApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PlaygroundApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary レコメンド求人を確認するテスト用API
     * @param {boolean} [isExcludeFeedbacked] FB済み以外洗い替えをするか否かのフラグ
     * @param {boolean} [shouldDelete] 洗い替えをするか否かのフラグ
     * @param {number} [wgPersonality] wg_personality
     * @param {number} [wgWantTo] wg_want_to
     * @param {number} [wgDistance] wg_distance
     * @param {number} [wgDesiredJobCategories] wg_desired_job_categories
     * @param {number} [wgDesiredWorkEnvironments] wg_desired_work_environments
     * @param {number} [wgDesiredWorkStyles] wg_desired_work_styles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRecommendationJob(
      isExcludeFeedbacked?: boolean,
      shouldDelete?: boolean,
      wgPersonality?: number,
      wgWantTo?: number,
      wgDistance?: number,
      wgDesiredJobCategories?: number,
      wgDesiredWorkEnvironments?: number,
      wgDesiredWorkStyles?: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScoutList>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRecommendationJob(
        isExcludeFeedbacked,
        shouldDelete,
        wgPersonality,
        wgWantTo,
        wgDistance,
        wgDesiredJobCategories,
        wgDesiredWorkEnvironments,
        wgDesiredWorkStyles,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * PlaygroundApi - factory interface
 * @export
 */
export const PlaygroundApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PlaygroundApiFp(configuration)
  return {
    /**
     *
     * @summary レコメンド求人を確認するテスト用API
     * @param {boolean} [isExcludeFeedbacked] FB済み以外洗い替えをするか否かのフラグ
     * @param {boolean} [shouldDelete] 洗い替えをするか否かのフラグ
     * @param {number} [wgPersonality] wg_personality
     * @param {number} [wgWantTo] wg_want_to
     * @param {number} [wgDistance] wg_distance
     * @param {number} [wgDesiredJobCategories] wg_desired_job_categories
     * @param {number} [wgDesiredWorkEnvironments] wg_desired_work_environments
     * @param {number} [wgDesiredWorkStyles] wg_desired_work_styles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecommendationJob(
      isExcludeFeedbacked?: boolean,
      shouldDelete?: boolean,
      wgPersonality?: number,
      wgWantTo?: number,
      wgDistance?: number,
      wgDesiredJobCategories?: number,
      wgDesiredWorkEnvironments?: number,
      wgDesiredWorkStyles?: number,
      options?: any,
    ): AxiosPromise<ScoutList> {
      return localVarFp
        .getRecommendationJob(
          isExcludeFeedbacked,
          shouldDelete,
          wgPersonality,
          wgWantTo,
          wgDistance,
          wgDesiredJobCategories,
          wgDesiredWorkEnvironments,
          wgDesiredWorkStyles,
          options,
        )
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * PlaygroundApi - object-oriented interface
 * @export
 * @class PlaygroundApi
 * @extends {BaseAPI}
 */
export class PlaygroundApi extends BaseAPI {
  /**
   *
   * @summary レコメンド求人を確認するテスト用API
   * @param {boolean} [isExcludeFeedbacked] FB済み以外洗い替えをするか否かのフラグ
   * @param {boolean} [shouldDelete] 洗い替えをするか否かのフラグ
   * @param {number} [wgPersonality] wg_personality
   * @param {number} [wgWantTo] wg_want_to
   * @param {number} [wgDistance] wg_distance
   * @param {number} [wgDesiredJobCategories] wg_desired_job_categories
   * @param {number} [wgDesiredWorkEnvironments] wg_desired_work_environments
   * @param {number} [wgDesiredWorkStyles] wg_desired_work_styles
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlaygroundApi
   */
  public getRecommendationJob(
    isExcludeFeedbacked?: boolean,
    shouldDelete?: boolean,
    wgPersonality?: number,
    wgWantTo?: number,
    wgDistance?: number,
    wgDesiredJobCategories?: number,
    wgDesiredWorkEnvironments?: number,
    wgDesiredWorkStyles?: number,
    options?: AxiosRequestConfig,
  ) {
    return PlaygroundApiFp(this.configuration)
      .getRecommendationJob(
        isExcludeFeedbacked,
        shouldDelete,
        wgPersonality,
        wgWantTo,
        wgDistance,
        wgDesiredJobCategories,
        wgDesiredWorkEnvironments,
        wgDesiredWorkStyles,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * ScoutApi - axios parameter creator
 * @export
 */
export const ScoutApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary 求職者がスカウト関連情報登録をするAPI
     * @param {AddScoutProfileRequest} addScoutProfileRequest 求職者がスカウト利用を開始する際に必要なパラメーター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addScoutProfile: async (
      addScoutProfileRequest: AddScoutProfileRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'addScoutProfileRequest' is not null or undefined
      assertParamExists('addScoutProfile', 'addScoutProfileRequest', addScoutProfileRequest)
      const localVarPath = `/scout/profile/add_scout_profile`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        addScoutProfileRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary スカウトフィードバック！
     * @param {ScoutFeedbackRequest} scoutFeedbackRequest 求職者がスカウトのフィードバックするのに必要なパラメーター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createScoutFeedback: async (
      scoutFeedbackRequest: ScoutFeedbackRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'scoutFeedbackRequest' is not null or undefined
      assertParamExists('createScoutFeedback', 'scoutFeedbackRequest', scoutFeedbackRequest)
      const localVarPath = `/scout/feedback`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        scoutFeedbackRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary スカウトを単体取得するAPI
     * @param {string} scoutId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getScout: async (scoutId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'scoutId' is not null or undefined
      assertParamExists('getScout', 'scoutId', scoutId)
      const localVarPath = `/scout/{scout_id}`.replace(
        `{${'scout_id'}}`,
        encodeURIComponent(String(scoutId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 動画スカウトを単体取得するAPI
     * @param {string} scoutId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getScoutVideo: async (
      scoutId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'scoutId' is not null or undefined
      assertParamExists('getScoutVideo', 'scoutId', scoutId)
      const localVarPath = `/scout/{scout_id}/movie`.replace(
        `{${'scout_id'}}`,
        encodeURIComponent(String(scoutId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 求職者が自身に届いたスカウトを取得するAPI
     * @param {number} [page] ページ番号
     * @param {Array<'liked' | 'not_interested' | 'unread'>} [scoutType] 種別（いいねしたスカウト、興味なしのスカウト、未読スカウト）
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexScouts: async (
      page?: number,
      scoutType?: Array<'liked' | 'not_interested' | 'unread'>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/scout`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (page !== undefined) {
        localVarQueryParameter['page'] = page
      }

      if (scoutType) {
        localVarQueryParameter['scout_type[]'] = scoutType
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 公開求人の件数を取得するAPI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/scout/job/count`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 求人タブ既読
     * @param {JobTabReadRequest} jobTabReadRequest 対象のスカウトを指定する為のパラメーター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobTabRead: async (
      jobTabReadRequest: JobTabReadRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobTabReadRequest' is not null or undefined
      assertParamExists('jobTabRead', 'jobTabReadRequest', jobTabReadRequest)
      const localVarPath = `/scout/job_tab_read`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        jobTabReadRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 求職者がスカウト関連情報登録をするAPI
     * @param {LikedReminderClickedRequest} likedReminderClickedRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    likedReminderClicked: async (
      likedReminderClickedRequest: LikedReminderClickedRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'likedReminderClickedRequest' is not null or undefined
      assertParamExists(
        'likedReminderClicked',
        'likedReminderClickedRequest',
        likedReminderClickedRequest,
      )
      const localVarPath = `/scout/clicked/liked_reminder`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        likedReminderClickedRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 求職者が自身に届いたスカウトをパック指定で取得するAPI
     * @param {'selected' | 'popular'} packType パックの種類 (厳選、 人気)
     * @param {'desired_job_categories' | 'desired_work_styles' | 'desired_work_environments'} masterName 選択マスター名（職種、働き方、仕事環境）
     * @param {number} tagId 選択タグID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pack: async (
      packType: 'selected' | 'popular',
      masterName: 'desired_job_categories' | 'desired_work_styles' | 'desired_work_environments',
      tagId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'packType' is not null or undefined
      assertParamExists('pack', 'packType', packType)
      // verify required parameter 'masterName' is not null or undefined
      assertParamExists('pack', 'masterName', masterName)
      // verify required parameter 'tagId' is not null or undefined
      assertParamExists('pack', 'tagId', tagId)
      const localVarPath = `/scout/pack/{pack_type}/{master_name}/{tag_id}`
        .replace(`{${'pack_type'}}`, encodeURIComponent(String(packType)))
        .replace(`{${'master_name'}}`, encodeURIComponent(String(masterName)))
        .replace(`{${'tag_id'}}`, encodeURIComponent(String(tagId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 求職者の希望タグごとのスカウト件数を取得するAPI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    packCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/scout/pack/count`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ScoutApi - functional programming interface
 * @export
 */
export const ScoutApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ScoutApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary 求職者がスカウト関連情報登録をするAPI
     * @param {AddScoutProfileRequest} addScoutProfileRequest 求職者がスカウト利用を開始する際に必要なパラメーター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addScoutProfile(
      addScoutProfileRequest: AddScoutProfileRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addScoutProfile(
        addScoutProfileRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary スカウトフィードバック！
     * @param {ScoutFeedbackRequest} scoutFeedbackRequest 求職者がスカウトのフィードバックするのに必要なパラメーター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createScoutFeedback(
      scoutFeedbackRequest: ScoutFeedbackRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TalentScout>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createScoutFeedback(
        scoutFeedbackRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary スカウトを単体取得するAPI
     * @param {string} scoutId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getScout(
      scoutId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TalentScout>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getScout(scoutId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary 動画スカウトを単体取得するAPI
     * @param {string} scoutId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getScoutVideo(
      scoutId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TalentScoutVideo>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getScoutVideo(scoutId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary 求職者が自身に届いたスカウトを取得するAPI
     * @param {number} [page] ページ番号
     * @param {Array<'liked' | 'not_interested' | 'unread'>} [scoutType] 種別（いいねしたスカウト、興味なしのスカウト、未読スカウト）
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async indexScouts(
      page?: number,
      scoutType?: Array<'liked' | 'not_interested' | 'unread'>,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Scouts>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.indexScouts(
        page,
        scoutType,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary 公開求人の件数を取得するAPI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobCount(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobCount200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobCount(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary 求人タブ既読
     * @param {JobTabReadRequest} jobTabReadRequest 対象のスカウトを指定する為のパラメーター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobTabRead(
      jobTabReadRequest: JobTabReadRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobTabRead(
        jobTabReadRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary 求職者がスカウト関連情報登録をするAPI
     * @param {LikedReminderClickedRequest} likedReminderClickedRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async likedReminderClicked(
      likedReminderClickedRequest: LikedReminderClickedRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.likedReminderClicked(
        likedReminderClickedRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary 求職者が自身に届いたスカウトをパック指定で取得するAPI
     * @param {'selected' | 'popular'} packType パックの種類 (厳選、 人気)
     * @param {'desired_job_categories' | 'desired_work_styles' | 'desired_work_environments'} masterName 選択マスター名（職種、働き方、仕事環境）
     * @param {number} tagId 選択タグID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pack(
      packType: 'selected' | 'popular',
      masterName: 'desired_job_categories' | 'desired_work_styles' | 'desired_work_environments',
      tagId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pack200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.pack(
        packType,
        masterName,
        tagId,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary 求職者の希望タグごとのスカウト件数を取得するAPI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async packCount(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PackCount>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.packCount(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ScoutApi - factory interface
 * @export
 */
export const ScoutApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ScoutApiFp(configuration)
  return {
    /**
     *
     * @summary 求職者がスカウト関連情報登録をするAPI
     * @param {AddScoutProfileRequest} addScoutProfileRequest 求職者がスカウト利用を開始する際に必要なパラメーター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addScoutProfile(
      addScoutProfileRequest: AddScoutProfileRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .addScoutProfile(addScoutProfileRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary スカウトフィードバック！
     * @param {ScoutFeedbackRequest} scoutFeedbackRequest 求職者がスカウトのフィードバックするのに必要なパラメーター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createScoutFeedback(
      scoutFeedbackRequest: ScoutFeedbackRequest,
      options?: any,
    ): AxiosPromise<TalentScout> {
      return localVarFp
        .createScoutFeedback(scoutFeedbackRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary スカウトを単体取得するAPI
     * @param {string} scoutId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getScout(scoutId: string, options?: any): AxiosPromise<TalentScout> {
      return localVarFp.getScout(scoutId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 動画スカウトを単体取得するAPI
     * @param {string} scoutId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getScoutVideo(scoutId: string, options?: any): AxiosPromise<TalentScoutVideo> {
      return localVarFp.getScoutVideo(scoutId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 求職者が自身に届いたスカウトを取得するAPI
     * @param {number} [page] ページ番号
     * @param {Array<'liked' | 'not_interested' | 'unread'>} [scoutType] 種別（いいねしたスカウト、興味なしのスカウト、未読スカウト）
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexScouts(
      page?: number,
      scoutType?: Array<'liked' | 'not_interested' | 'unread'>,
      options?: any,
    ): AxiosPromise<Scouts> {
      return localVarFp
        .indexScouts(page, scoutType, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 公開求人の件数を取得するAPI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobCount(options?: any): AxiosPromise<JobCount200Response> {
      return localVarFp.jobCount(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 求人タブ既読
     * @param {JobTabReadRequest} jobTabReadRequest 対象のスカウトを指定する為のパラメーター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobTabRead(jobTabReadRequest: JobTabReadRequest, options?: any): AxiosPromise<void> {
      return localVarFp
        .jobTabRead(jobTabReadRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 求職者がスカウト関連情報登録をするAPI
     * @param {LikedReminderClickedRequest} likedReminderClickedRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    likedReminderClicked(
      likedReminderClickedRequest: LikedReminderClickedRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .likedReminderClicked(likedReminderClickedRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 求職者が自身に届いたスカウトをパック指定で取得するAPI
     * @param {'selected' | 'popular'} packType パックの種類 (厳選、 人気)
     * @param {'desired_job_categories' | 'desired_work_styles' | 'desired_work_environments'} masterName 選択マスター名（職種、働き方、仕事環境）
     * @param {number} tagId 選択タグID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pack(
      packType: 'selected' | 'popular',
      masterName: 'desired_job_categories' | 'desired_work_styles' | 'desired_work_environments',
      tagId: number,
      options?: any,
    ): AxiosPromise<Pack200Response> {
      return localVarFp
        .pack(packType, masterName, tagId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 求職者の希望タグごとのスカウト件数を取得するAPI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    packCount(options?: any): AxiosPromise<PackCount> {
      return localVarFp.packCount(options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * ScoutApi - object-oriented interface
 * @export
 * @class ScoutApi
 * @extends {BaseAPI}
 */
export class ScoutApi extends BaseAPI {
  /**
   *
   * @summary 求職者がスカウト関連情報登録をするAPI
   * @param {AddScoutProfileRequest} addScoutProfileRequest 求職者がスカウト利用を開始する際に必要なパラメーター
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScoutApi
   */
  public addScoutProfile(
    addScoutProfileRequest: AddScoutProfileRequest,
    options?: AxiosRequestConfig,
  ) {
    return ScoutApiFp(this.configuration)
      .addScoutProfile(addScoutProfileRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary スカウトフィードバック！
   * @param {ScoutFeedbackRequest} scoutFeedbackRequest 求職者がスカウトのフィードバックするのに必要なパラメーター
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScoutApi
   */
  public createScoutFeedback(
    scoutFeedbackRequest: ScoutFeedbackRequest,
    options?: AxiosRequestConfig,
  ) {
    return ScoutApiFp(this.configuration)
      .createScoutFeedback(scoutFeedbackRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary スカウトを単体取得するAPI
   * @param {string} scoutId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScoutApi
   */
  public getScout(scoutId: string, options?: AxiosRequestConfig) {
    return ScoutApiFp(this.configuration)
      .getScout(scoutId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 動画スカウトを単体取得するAPI
   * @param {string} scoutId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScoutApi
   */
  public getScoutVideo(scoutId: string, options?: AxiosRequestConfig) {
    return ScoutApiFp(this.configuration)
      .getScoutVideo(scoutId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 求職者が自身に届いたスカウトを取得するAPI
   * @param {number} [page] ページ番号
   * @param {Array<'liked' | 'not_interested' | 'unread'>} [scoutType] 種別（いいねしたスカウト、興味なしのスカウト、未読スカウト）
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScoutApi
   */
  public indexScouts(
    page?: number,
    scoutType?: Array<'liked' | 'not_interested' | 'unread'>,
    options?: AxiosRequestConfig,
  ) {
    return ScoutApiFp(this.configuration)
      .indexScouts(page, scoutType, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 公開求人の件数を取得するAPI
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScoutApi
   */
  public jobCount(options?: AxiosRequestConfig) {
    return ScoutApiFp(this.configuration)
      .jobCount(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 求人タブ既読
   * @param {JobTabReadRequest} jobTabReadRequest 対象のスカウトを指定する為のパラメーター
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScoutApi
   */
  public jobTabRead(jobTabReadRequest: JobTabReadRequest, options?: AxiosRequestConfig) {
    return ScoutApiFp(this.configuration)
      .jobTabRead(jobTabReadRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 求職者がスカウト関連情報登録をするAPI
   * @param {LikedReminderClickedRequest} likedReminderClickedRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScoutApi
   */
  public likedReminderClicked(
    likedReminderClickedRequest: LikedReminderClickedRequest,
    options?: AxiosRequestConfig,
  ) {
    return ScoutApiFp(this.configuration)
      .likedReminderClicked(likedReminderClickedRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 求職者が自身に届いたスカウトをパック指定で取得するAPI
   * @param {'selected' | 'popular'} packType パックの種類 (厳選、 人気)
   * @param {'desired_job_categories' | 'desired_work_styles' | 'desired_work_environments'} masterName 選択マスター名（職種、働き方、仕事環境）
   * @param {number} tagId 選択タグID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScoutApi
   */
  public pack(
    packType: 'selected' | 'popular',
    masterName: 'desired_job_categories' | 'desired_work_styles' | 'desired_work_environments',
    tagId: number,
    options?: AxiosRequestConfig,
  ) {
    return ScoutApiFp(this.configuration)
      .pack(packType, masterName, tagId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 求職者の希望タグごとのスカウト件数を取得するAPI
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScoutApi
   */
  public packCount(options?: AxiosRequestConfig) {
    return ScoutApiFp(this.configuration)
      .packCount(options)
      .then((request) => request(this.axios, this.basePath))
  }
}
