import { datadogRum } from '@datadog/browser-rum'

import { appEnv, version } from '@/config'
import { isProd, isStg } from '@/modules/utils/env'

// const applicationId = process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID as string
// const clientToken = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN as string

// 環境変数がなぜか読み込まれていないので、prod/stgのみでベタガキに
if (isProd || isStg) {
  datadogRum.init({
    applicationId: '7da95470-fd1b-4bd8-9b25-75552ac47d9a',
    clientToken: 'pub8bbb20cae523f5d4e0217f41329504fe',
    version: version || '',
    service: 'z-career-talent',
    /* eslint no-nested-ternary: "off" */
    env: isProd ? 'prod' : isStg ? 'stg' : appEnv || 'none',
    site: 'datadoghq.com',
    datacenter: 'us',
    traceSampleRate: 100,
    trackUserInteractions: true,
    allowedTracingUrls: ['https://api.zcareer.com', /https:\/\/api.*\.zcareer.com/],
  })
  datadogRum.startSessionReplayRecording()
}
