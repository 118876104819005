import { useEffect } from 'react'
import dynamic from 'next/dynamic'

// 'jotai-devtools' を動的にインポート
const DevTools = dynamic(() => import('jotai-devtools').then((mod) => mod.DevTools), {
  ssr: false, // サーバーサイドではレンダリングしない
}) // ここで静的に読み込む代わりに...

export const JotaiDevTool = () => {
  useEffect(() => {
    // CSS を遅延インポート
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    import('jotai-devtools/styles.css')
  }, [])

  return <DevTools />
}
