import { ReactNode, useEffect } from 'react'
import { Box, ChakraProvider } from '@chakra-ui/react'
import { createStandaloneToast } from '@chakra-ui/toast'
import { AxiosError } from 'axios'
import { GetServerSidePropsContext } from 'next'
import type { AppPropsWithLayout } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { PrimeReactProvider } from 'primereact/api'

import '@/utils/datadogRum'

import { getTalentApiUrl } from '@/api/apiHostNameSwitcher'
import { axiosInstance } from '@/api/axiosInstance'
import { defaultMetaTitle } from '@/config'
import { authCookieName, useAuthCheck } from '@/hooks/useAuth'
import { useRedirectAfterLogin } from '@/hooks/useRedirectAfterLogin'
import { JotaiDevTool } from '@/modules/components/JotaiDevTool'
import { isProd, isStg } from '@/modules/utils/env'
import { useInitGTM } from '@/modules/utils/gtm/gtmEventActions'
import serverSideLogger from '@/modules/utils/serverSideLogger'
import { theme } from '@/modules/utils/theme'
import { AuthApi, MeTalent } from '@/oas/talent/api'
import { ScrollRestoration } from '@/utils/scrollRestoration'

import '../styles/globals.css'

type Props = AppPropsWithLayout<{ me: MeTalent | null }>

const MyApp = ({ Component, pageProps }: Props) => {
  if (isProd || isStg) {
    // GTMの初期化
    useInitGTM()
  }

  // SSRで取得したログイン情報をglobal stateに保存
  useAuthCheck(pageProps.me)

  const { ToastContainer } = createStandaloneToast()

  const getLayout = Component.getLayout || ((page: ReactNode) => page)

  const { removeToUrl, setToUrl } = useRedirectAfterLogin()
  const router = useRouter()

  useEffect(() => {
    removeToUrl()
  }, [])

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      setToUrl(url)
    }
    router.events.on('routeChangeStart', handleRouteChange)
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router])

  return (
    <Box>
      {!isProd && !isStg && <JotaiDevTool />}
      <Head>
        <title>{defaultMetaTitle}</title>
      </Head>
      <ChakraProvider theme={theme}>
        <PrimeReactProvider>
          <ScrollRestoration />
          <ToastContainer />
          {getLayout(<Component {...pageProps} />)}
        </PrimeReactProvider>
      </ChakraProvider>
    </Box>
  )
}

// 各種ページのgetServerSidePropsより先に実行される
const authApi = new AuthApi(undefined, getTalentApiUrl(), axiosInstance)
MyApp.getInitialProps = async ({ ctx }: { ctx: GetServerSidePropsContext }) => {
  ctx.res?.setHeader('Content-Type', 'text/html')
  const token = ctx.req?.cookies?.[authCookieName]

  try {
    // まず、ログイン状態を確認して、ログインしている場合はログインユーザー情報を取得
    // 認証APIをサーバー以外で叩きたくないため、関数化しない
    const me = token
      ? await authApi.getMe({
          headers: token
            ? {
                Cookie: `${authCookieName}=${token}`,
              }
            : {},
          withCredentials: true,
        })
      : null

    return {
      pageProps: {
        me: me?.data ?? null,
      },
    }
  } catch (err) {
    if (err instanceof AxiosError) {
      if (err?.response?.status === 401) {
        ctx.res.setHeader(
          'Set-Cookie',
          `${authCookieName}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`,
        )
      }
    }
    serverSideLogger.error({
      msg: {
        err,
      },
    })
    return {
      pageProps: {
        me: null,
      },
    }
  }
}

export default MyApp
