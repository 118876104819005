import { extendTheme } from '@chakra-ui/react'

export const theme = extendTheme({
  components: {
    Select: {
      baseStyle: {
        field: {
          _placeholder: {
            color: '#A0AEC0',
          },
          '&::placeholder': {
            color: '#A0AEC0',
          },
        },
      },
    },
    Drawer: {
      sizes: {
        scoutTargetSettingDrawerSize: {
          dialog: {
            maxW: '480px',
          },
        },
      },
    },
  },
  styles: {
    global: {
      body: {
        bg: 'gray.100',
        color: 'gray.800',
        fontFamily: `"YakuHanJPs","-apple-system","BlinkMacSystemFont","Segoe UI","Hiragino Sans","Hiragino Kaku Gothic ProN","Meiryo",sans-serif;`,
        textDecoration: 'none',
      },
    },
  },
  colors: {
    brand: {
      red: '#FF6774',
      'red-right': '#FFE1E3',
      'red-deep': '#A34B57',
      yellow: '#EFBC37',
      'yellow-right': '#FCF2D7',
      'yellow-deep': '#9A7E33',
      green: '#4BD281',
      'green-right': '#DBF6E6',
      'green-deep': '#378B5F',
      blue: '#25A3DB',
      'blue-right': '#D3EDF8',
      'blue-deep': '#216F95',
      purple: '#B399E9',
      'purple-right': '#F0EBFB',
      'purple-deep': '#76699D',
    },
    text: {
      primary: '#1A202C',
      secondary: '#4A5568',
      placeholder: '#A0AEC0',
      invert: 'white',
      link: '#25A3DB',
      error: 'red.500',
    },
    border: {
      primary: '#E2E8F0',
    },
    surface: {
      primary: 'white',
      'reverse-primary': '#1A202C',
      secondary: '#EDF2F7',
      'reverse-secondary': '#2D3748',
      keep: '#FF6774',
      reverse: {
        secondary: '#2D3748',
      },
    },
  },
  // テキストスタイルの定義
  textStyles: {
    // companyやadminを含む
    biz: {
      heading: {
        lg: {
          fontSize: '20px',
          lineHeight: '140%',
          fontWeight: 'bold',
        },
        md: {
          fontSize: '18px',
          lineHeight: '140%',
          fontWeight: 'bold',
        },
        sm: {
          fontSize: '16px',
          lineHeight: '140%',
          fontWeight: 'bold',
        },
      },
      body: {
        lg: {
          fontSize: '16px',
          lineHeight: '150%',
        },
        md: {
          fontSize: '14px',
          lineHeight: '150%',
        },
        sm: {
          fontSize: '12px',
          lineHeight: '150%',
        },
      },
      help: {
        fontSize: '12px',
        lineHeight: '150%',
      },
      table: {
        head: {
          fontSize: '12px',
          lineHeight: '150%',
          fontWeight: 'bold',
        },
        item: {
          fontSize: '14px',
          lineHeight: '150%',
        },
      },
    },
    talent: {
      heading: {
        lg: {
          fontSize: '18px',
          lineHeight: '150%',
          fontWeight: 'bold',
        },
        md: {
          fontSize: '16px',
          lineHeight: '150%',
          fontWeight: 'bold',
        },
        sm: {
          fontSize: '14px',
          lineHeight: '150%',
          fontWeight: 'bold',
        },
      },
      body: {
        lg: {
          fontSize: '15px',
          lineHeight: '175%',
        },
        md: {
          fontSize: '13px',
          lineHeight: '175%',
        },
        sm: {
          fontSize: '11px',
          lineHeight: '150%',
        },
      },
      help: {
        fontSize: '12px',
        lineHeight: '150%',
      },
      jobTitle: {
        lg: {
          fontSize: '18px',
          lineHeight: '150%',
          fontWeight: 'bold',
        },
        md: {
          fontSize: '16px',
          lineHeight: '150%',
          fontWeight: 'bold',
        },
      },
    },
  },
})
