import { publicApiUrl, publicApiUrlForSsr, talentApiUrl, talentApiUrlForSsr } from '@/config'

const isClient = () => typeof window !== 'undefined'

/**
 * Why: SSR時はInternalなネットワークを通じてバックエンドと通信する。
 * 内部通信用のホスト名は、当然にVPC内でしか解決できないため、ユーザーのブラウザからのリクエストでは使うことができない
 */
export const getTalentApiUrl = () => {
  return isClient() ? talentApiUrl : talentApiUrlForSsr
}
export const getPublicApiUrl = () => {
  return isClient() ? publicApiUrl : publicApiUrlForSsr
}
