/* eslint-disable no-console */
import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { AxiosError } from 'axios'
import { useAtomValue } from 'jotai'
import useSWR from 'swr'

import { fetchGlobalBadgesApi } from '@/api/authApi'
import { authMeAtom } from '@/globalState/auth'

export interface KeptJobsCookie {
  jobId: string
  keepTime: string
}

export const useGlobalBadges = () => {
  const [keepCount, setKeepCount] = useState(0)
  const meAtom = useAtomValue(authMeAtom)
  const [cookies] = useCookies(['keptJobs'])

  const { data, isLoading, mutate } = useSWR(
    meAtom ? '/api/talent/auth/fetch_global_badges' : null,
    () =>
      fetchGlobalBadgesApi()
        .then((res) => res.data)
        .catch((e: AxiosError) => {
          throw e
        }),
    {
      // 画面遷移ごとに再取得する設定
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  )

  // Cookie取得値を普通にSSR向けに出すとHydrationエラーが発生するためuseEffectを介してしてCSR化して渡す
  useEffect(() => {
    if (meAtom) {
      // ログイン済みの場合はフェッチした値
      setKeepCount((data && data.keep_count) ?? 0)
    } else {
      // 未ログインの場合はCookieの値
      const keptJobs = cookies.keptJobs as KeptJobsCookie[]
      setKeepCount(keptJobs ? keptJobs.length : 0)
    }
  }, [meAtom, data?.keep_count, cookies.keptJobs])

  /**
   * キャッシュしているkeep_countの値をAPIフェッチを介さずに更新する
   */
  const localMutateKeepCount = (count: number) => {
    setKeepCount(count)
    const prev = data || { keep_count: 0, unread_scout_count: 0 }
    mutate({ ...prev, keep_count: count }, false)
  }

  return {
    isLoadingGlobalBadges: isLoading,
    keepCount,
    unreadScoutCount: data && data.unread_scout_count,
    mutateGlobalBadges: mutate,
    localMutateKeepCount,
  }
}
