import { useRouter } from 'next/router'

import { localStorageKey } from '@/const/localStorage'

/**
 * ログイン後に元いたページに戻る機能のhooks
 * @returns
 */
export const useRedirectAfterLogin = () => {
  const router = useRouter()
  const removeToUrl = () => {
    localStorage.removeItem(localStorageKey.TO_URL_AFTER_LOGIN)
  }
  const setToUrl = (url: string) => {
    if (url === '/login') {
      localStorage.setItem(localStorageKey.TO_URL_AFTER_LOGIN, router.asPath)
    }
  }

  const getToUrl = () => {
    return localStorage.getItem(localStorageKey.TO_URL_AFTER_LOGIN) || '/'
  }
  return {
    removeToUrl,
    setToUrl,
    getToUrl,
  }
}
