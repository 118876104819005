import { axiosInstance } from '@/api/axiosInstance'
import { authCookieName } from '@/hooks/useAuth'
import { JobApi as JobApiPublic } from '@/oas/public/api'
import {
  BulkKeepJobRequest,
  DettachKeptJobsRequest,
  JobApi as JobApiTalent,
} from '@/oas/talent/api'

import { getPublicApiUrl, getTalentApiUrl } from './apiHostNameSwitcher'

const jobApiPublic = new JobApiPublic(undefined, getPublicApiUrl(), axiosInstance)
const jobApiTalent = new JobApiTalent(undefined, getTalentApiUrl(), axiosInstance)

/**
 * Why: 求人取得系のAPIリクエストは、認証状態に応じてアクセス先のAPIを分けたい
 */
const switchApiClient = (isAuthenticated: boolean) => {
  return isAuthenticated ? jobApiTalent : jobApiPublic
}

export const getJobListApi = (
  isAuthenticated: boolean,
  authCookie?: string,
  page?: number | undefined,
  workLocationPrefecture?: number[] | undefined,
  jobCategory?: number[] | undefined,
  particularCondition?: number[] | undefined,
  abRecruitmentCompanyId?: number | undefined,
) => {
  return switchApiClient(isAuthenticated).getJobList(
    page,
    workLocationPrefecture,
    jobCategory,
    particularCondition,
    abRecruitmentCompanyId,
    isAuthenticated
      ? {
          headers: {
            Cookie: `${authCookieName}=${authCookie}`,
          },
        }
      : undefined,
  )
}

/**
 * 一覧で「もっとみる」ボタンを押したときに呼ばれるAPI
 * @param isAuthenticated
 * @param page
 * @param workLocationPrefecture
 * @param jobCategory
 * @param particularCondition
 * @returns
 */
export const fetchJobListApi = (
  isAuthenticated: boolean,
  page?: number | undefined,
  workLocationPrefecture?: number[] | undefined,
  jobCategory?: number[] | undefined,
  particularCondition?: number[] | undefined,
) => {
  return switchApiClient(isAuthenticated).getJobList(
    page,
    workLocationPrefecture,
    jobCategory,
    particularCondition,
    undefined,
    {
      withCredentials: true,
    },
  )
}

export const getJobApi = (isAuthenticated: boolean, jobId: string, authCookie?: string) => {
  return switchApiClient(isAuthenticated).getJob(
    jobId,
    isAuthenticated
      ? {
          headers: {
            Cookie: `${authCookieName}=${authCookie}`,
          },
        }
      : undefined,
  )
}

export const getLimitedWorkLocationJobApi = (
  isAuthenticated: boolean,
  jobId: string,
  masterPrefectureId: string,
  authCookie?: string,
) => {
  return switchApiClient(isAuthenticated).getLimitedWorkLocationJob(jobId, masterPrefectureId, {
    headers: {
      Cookie: isAuthenticated ? `${authCookieName}=${authCookie}` : '',
    },
  })
}

export const getKeepJobsApi = (page?: number) => {
  return jobApiTalent.getKeptJobs(page, {
    withCredentials: true,
  })
}

export const postKeepJobsApi = (bulkKeepJobRequest?: BulkKeepJobRequest) => {
  return jobApiTalent.bulkKeepJob(bulkKeepJobRequest, {
    withCredentials: true,
  })
}

export const detachKeepJobsApi = (bulkKeepJobRequest?: DettachKeptJobsRequest) => {
  return jobApiTalent.dettachKeptJobs(bulkKeepJobRequest, {
    withCredentials: true,
  })
}

export const getJobsByIdsApi = (isAuthenticated: boolean, jobIds: string[]) => {
  return switchApiClient(isAuthenticated).fetchJobsByIds(jobIds)
}

export const getPreviewJobApi = (previewToken: string) => {
  return jobApiPublic.previewJob(previewToken)
}

export const getJobCountApi = (jobSearchConditions: {
  prefectures: number[] | string[]
  jobCategories: number[] | string[]
  particularConditions: number[] | string[]
}) => {
  return jobApiPublic.getJobCount(
    jobSearchConditions.prefectures.map(Number),
    jobSearchConditions.jobCategories.map(Number),
    jobSearchConditions.particularConditions.map(Number),
  )
}
