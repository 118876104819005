import { useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'

export const ScrollRestoration = () => {
  const router = useRouter()
  const scrollPositions = useRef<{ [key: string]: number }>({})
  const [popState, setIsPopState] = useState(false)

  useEffect(() => {
    // ページ移動前にスクロール位置を保持
    const handleRouterChangeStart = () => {
      scrollPositions.current[router.asPath] = window.scrollY
    }

    // ページ移動後にスクロール位置を復元
    const handleRouteChangeComplete = (url: string) => {
      if (popState) {
        const savedPosition = scrollPositions.current[url]
        if (url.endsWith('/keep-list') || url.endsWith('/inquiry/list') || url.includes('/scout')) {
          window.scrollTo(0, 0)
        } else if (savedPosition !== undefined) {
          window.scrollTo(0, savedPosition)
          setIsPopState(false)
        } else {
          window.scrollTo(0, 0)
        }
      } else {
        window.scrollTo(0, 0)
      }

      setIsPopState(false)
    }

    // ブラウザバック/ブラウザフォワードを監視
    const handlePopState = () => {
      setIsPopState(true)
    }

    router.events.on('routeChangeStart', handleRouterChangeStart)
    router.events.on('routeChangeComplete', handleRouteChangeComplete)
    window.addEventListener('popstate', handlePopState)

    return () => {
      router.events.off('routeChangeStart', handleRouterChangeStart)
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
      window.removeEventListener('popstate', handlePopState)
    }
  }, [router])
  return null
}
