import { useToast } from '@chakra-ui/toast'

export const useToastWrapper = () => {
  const toast = useToast()

  enum Status {
    warning = 'error',
    success = 'success',
  }

  const success = (description?: string) => {
    toast.closeAll()
    toast({
      title: description || '成功',
      status: Status.success,
      duration: 5000,
      isClosable: true,
      position: 'top-right',
    })
  }

  const error = (description?: string) => {
    toast.closeAll()
    toast({
      title: description || '失敗',
      status: Status.warning,
      duration: null,
      isClosable: true,
      position: 'top-right',
    })
  }

  const toastClose = () => {
    toast.closeAll()
  }

  return {
    success,
    error,
    toastClose,
  }
}
